.base-table-wrap {
  // 목록 테이블 컨테이너
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  padding-left: 30px;
  padding-right: 30px;
}
.base-table-scroll-warp {
  position: relative;
  overflow: auto;
}
.base-table {
  width: 100%;
  border-spacing: 0;
  height: 100%;
  border-collapse: collapse;
  word-break: keep-all;
  white-space: normal;

  &.left-shadow::before,
  &.right-shadow::after {
    content: "";
    z-index: 10;
    position: absolute;
    top: 0;
    width: 12px; /* 음영 효과의 너비 */
    height: 100%;
  }

  &.left-shadow::before {
    // background: linear-gradient(270deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.2) 100%);

    background: linear-gradient(270deg, #dddddd00, #0002);

    left: var(--left-shadow, 0); /* CSS 변수 사용 */
  }

  &.right-shadow::after {
    // background: linear-gradient(90deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
    background: linear-gradient(90deg, #dddddd00, #0002);

    right: var(--right-shadow, 0); /* CSS 변수 사용 */
  }

  .header {
    min-width: 100%;
  }
  .nested-table {
    width: 100%;

    margin-top: 10px;
    overflow: visible; /* 가로 스크롤 활성화 */
  }

  .nested-table .base-table__th,
  .nested-table .base-table__td {
    padding: 10px;
    text-align: center;
  }

  .nested-table .base-table__th {
    background-color: #fff;
    color: #333;
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
  }

  .nested-table .base-table__td {
    background-color: #f8f8f8;
  }

  .nested-table .base-table__td:hover {
    background-color: #e0e0e0;
  }

  .table_img {
    width: 45px;
    height: 45px;
    border-radius: 4px;
    object-fit: cover;
    object-position: center;
  }
  &__tr:hover .base-table__td {
    background-color: $bg_light;
  }
  &.sticky {
    overflow: auto;
    // 스크롤 그림자 효과 추가로 제거함
    // & [data-sticky-last-left-td] {
    //   box-shadow: none !important;
    // }
    .header,
    .footer {
      position: sticky;
      z-index: 1;
      width: fit-content;
    }
    .header {
      top: 0;
      //   box-shadow: 0px 3px 3px #ccc;
    }
    .footer {
      bottom: 0;
      //   box-shadow: 0px -3px 3px #ccc;
    }
    .body {
      position: relative;
      z-index: 0;
    }
    [data-sticky-td] {
      position: sticky;
    }
    // 스크롤 그림자 효과 추가로 제거함
    // [data-sticky-last-left-td] {
    //   box-shadow: 1px 0px 0px #ccc;
    // }
    // [data-sticky-first-right-td] {
    //   box-shadow: -1px 0px 0px #ccc;
    // }
  }

  &.view-data-table {
    overflow: visible;
  }

  &.two-depth-header {
    .base-table__tr {
      // margin-top: -1px;
    }
    .base-table__th {
      border-right: 1px solid $border-color;
      background-color: $white;
      position: relative;
      overflow: visible;
      text-align: center;
      &:last-child {
        border-right: none;
      }
      .no-parent {
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 100%;
        background-color: $white;
      }
      .sticky-parent {
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 100%;
        // background-color: blue;
        background-color: $white;
      }
    }
    .base-table__td {
      text-align: center;
    }
  }

  &__th,
  &__td {
    padding: 10px 10px;
  }

  &__th {
    //   width:100%;
    height: 50px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    text-align: center;
    background-color: $white;
    color: $text_primary3;
    border-bottom: 1px solid $line-light;
    border-top: 1px solid $line-light;
    white-space: pre-line;
  }

  &__td {
    height: 70px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    border-bottom: 1px solid $bg_dark;
    background-color: #fff;
    overflow: visible;
    //   width: 100% !important;
    &.base-table-footer {
      background-color: $bg_light;
    }
  }
  &__innerTd {
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    border-bottom: 1px solid $bg_dark;
    background-color: #fff;
    overflow: visible;
    position: relative;
    min-width: 200px; /* 최소 너비 설정 (적절한 값을 사용하세요) */
    margin-left: 30px;
  }

  .ic_ho {
    width: 14px;
    height: 14px;
    opacity: 0.5;
    background-size: contain;
    background-image: url("../../images/ic_corner_down_right.svg");
    background-repeat: no-repeat;
  }
  .ic_sort {
    width: 10px;
    min-width: 10px;
    height: 10px;
    background-size: contain;
    margin-left: 4px;
    background-image: url("../../images/ic_sort.svg");
    background-repeat: no-repeat;
    &.down {
      background-image: url("../../images/ic_sort_down.svg");
      background-repeat: no-repeat;
    }
    &.up {
      background-image: url("../../images/ic_sort_up.svg");
      background-repeat: no-repeat;
    }
  }

  .footer .tr:last-child .td {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #4caf50;
    color: white;
  }
}
// 탭내의 테이블일 경우
.inner-tab-table {
  margin-bottom: 10px;
  .base-table {
    &__td {
      height: $inner-tab-table;
      // padding-left: 20px;
      // padding-right: 20px;
    }
    &__td,
    &__th {
      &:first-child {
        // padding-left: 16px;
      }
    }
  }
  .base-pagination {
    padding: 0;
  }
  &.two-depth-header {
    .base-table__tr {
      margin-top: -1px;
    }
    .base-table__th {
      border-right: 1px solid $border-color;
      background-color: $white;
      position: relative;
      overflow: visible;
      text-align: center;
      &:last-child {
        border-right: none;
      }
      .no-parent {
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 100%;
        background-color: $white;
      }
      .sticky-parent {
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 100%;
        // background-color: blue;
        background-color: $white;
      }
    }
    .base-table__td {
      text-align: center;
    }
  }
}

.base-pagination {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  height: $pagination-height;
  padding: 0;
  position: relative;

  &__total {
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .react-select-container {
      padding: 0;
      width: 100%;
      .react-select__control {
        min-height: 20px;
        height: 20px;
        border-color: white;
        cursor: pointer;
        border-bottom: 1px solid $text-primary;
        border-radius: 0;

        &:hover {
          border-color: white;
          border-bottom: 1px solid $text-primary;
          // border-radius: 0;
        }

        :first-child {
          padding: 0;
        }
      }
    }

    .base-select {
      .react-select {
        &__menu {
          z-index: $select-z-in;
          padding: 8px;
          margin-top: -240px;
          box-shadow: unset;
          filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.1));
          border: none;
          border-radius: 10px;
          width: 150px;
          left: -17px;
        }

        &__menu-list {
          @include scroll-hidden;
          .react-select__option {
            font-size: 1.4rem;
            display: flex;
            align-items: center;
            min-height: 36px;
          }
        }
        &__indicators {
          // margin-right: 10px;
          display: none;
        }
      }
    }
  }

  button:not(.base-btn) {
    width: 30px;
    height: 30px;
    border: none;
    font-size: 1.4rem;
    border-radius: 4px;
    cursor: pointer;
  }
  &__previous {
    background-image: url("../../images/ic_previous.svg");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: center;
    background-color: transparent;
    &.prev-10 {
      background-image: url("../../images/ic_previous_10.svg");
    }
    &.prev-all {
      background-image: url("../../images/ic_previous_all.svg");
    }
    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
  &__number {
    background-color: transparent;
    white-space: nowrap;

    &:disabled {
      background-color: map-get($grays, "200");
      color: map-get($grays, "900");
      cursor: default;
    }
  }
  &__next {
    background-image: url("../../images/ic_next.svg");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: center;
    background-color: transparent;
    &.next-10 {
      background-image: url("../../images/ic_next_10.svg");
    }
    &.next-all {
      background-image: url("../../images/ic_next_all.svg");
    }
    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
  .child-btn-area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    button.base-btn {
      min-width: 80px;
    }
  }
}

.inner-table {
  border-collapse: collapse;
  border-spacing: 0;
  th {
    height: 40px;
    background-color: map-get($grays, "100");
    font-size: 1.4rem;
    color: map-get($grays, "600");
    font-weight: 400;
  }
  td {
    font-size: 1.4rem;
    height: 50px;
    max-height: 50px;
    // overflow:hidden;
    > div {
      display: flex;
      align-items: center;
      min-height: 50px;
      height: auto;
      .base-search-btn {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid map-get($grays, "200");
      &.auto-height {
        td {
          height: auto;
          .h-40 {
            height: 40px;
            border-bottom: 1px solid map-get($grays, "200");
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
    tr.border-top {
      border-top: 1px solid map-get($grays, "200");
    }
  }
  &__img {
    width: 34px;
    height: 34px;
    object-fit: cover;
    background-position: center;
    border-radius: 4px;
  }
}
