.dim {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  margin: 0 auto;

  .base-loading-bar {
    margin: 0 auto;
  }

  &.bottom {
    align-items: flex-end;
  }

  &.all-black {
    background-color: #000;
    display: block;
    align-items: unset;
  }

  // 모바일일 경우 딤 하위 모든 케이스
  &.mobile {
    max-width: 500px;

    .confirm-modal {
      width: calc(100% - 48px);
    }

    .base-modal {
      width: calc(100% - 48px);
      padding: 0 20px;

      .base-modal__contents {
        padding-top: 56px;
        color: $text-primary4;
      }

      &__title {
        width: calc(100% - 40px);
        height: $modal-title-height;
        display: flex;
        align-items: center;
        position: absolute;
        background-color: $white;
        top: 0;
        justify-content: space-between;

        span {
          font-size: 1.8rem;
          font-weight: 600;
          margin-top: 10px;
        }
      }

      &__contents {
        padding: 30px 0;
        color: $text-primary;
      }
    }
  }
}

// 컨펌모달
.confirm-modal {
  min-width: 200px;
  width: 350px;
  height: auto;
  max-height: $modal-contents-height;
  margin: 0 auto;
  padding: 30px 60px;
  border-radius: 8px;
  background-color: #fff;
  animation: top-to-bottom 0.3s;
  overflow: auto;
  position: relative;
  @include scroll-hidden;

  &__title {
    p {
      font-size: 1.8rem;
      font-weight: 600;
      text-align: center;
    }
  }

  &__contents {
    padding-bottom: 30px;
    font-size: 1.4rem;
    text-align: center;
  }

  &__btn-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .base-btn-mobile {
    width: fit-content;
    min-width: 120px;
    height: 40px;
    font-weight: 600;
  }
}

// 뼈대만 있는 모달
.base-abstract-modal {
  min-width: 350px;
  width: 1000px;
  min-width: 1000px;
  height: auto;
  max-height: calc(#{$full_height} - 80px);
  margin: 0 auto;
  border-radius: 10px;
  background-color: #fff;
  animation: top-to-bottom 0.3s;
  position: relative;
  display: flex;
  flex-direction: column;
  &__title {
    width: 100%;
    // height: $modal-dialog-title-height;
    display: flex;
    align-items: center;
    padding: 30px;
    &.border-bottom {
      border-bottom: 1px solid $bg_dark;
    }
    h1 {
      font-size: 2.4rem;
      font-weight: bold;
    }
    &.has-list {
      display: flex;
      align-items: flex-start;
      padding-bottom: 20px;
    }
  }
  &__contents {
    flex: 1;
    height: auto;
    overflow-y: auto;
    &-subtitle {
      width: 100%;
      min-height: 66px;
      display: flex;
      align-items: center;
      margin-top: 10px;
      justify-content: space-between;
      h2 {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 2.6rem;
      }
    }
    &-table-wrap {
      height: auto;
      max-height: 300px;
      flex: none;
      overflow-x: hidden;
    }
    &-minmax-height {
      min-height: 367px;
      height: auto;
      max-height: 1017px;
      overflow-x: hidden;
    }
  }
  &__btn-wrap {
    width: 100%;
    height: $modal-dialog-btn-wrap-height;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    button.base-btn {
      min-width: 80px;
    }
  }
  &.small {
    width: 350px;
    min-width: 350px;
  }
  &.medium {
    width: 540px;
    min-width: 540px;
  }
  &.large {
    width: 760px;
    min-width: 760px;
  }
  &.xlarge {
    width: 1000px;
    min-width: 1000px;
  }
  &.two-depth {
    width: 760px;
    min-width: 760px;
    max-height: calc(#{$full_height} - 140px);
  }
  .base-table__td {
    height: 50px;
  }
  .contents-container {
    padding-left: unset;
    height: auto;
  }
  .contents-title__tab > div {
    height: 50px;
  }
}

// 베이스모달
.base-modal {
  min-width: 200px;
  width: 350px;
  height: auto;
  max-height: calc(#{$full_height} - 48px);
  margin: 0 auto;
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
  animation: top-to-bottom 0.3s;
  position: relative;

  &__title {
    p {
      font-size: 1.8rem;
      font-weight: 600;
      text-align: center;
    }
  }

  &__contents {
    font-size: 1.4rem;
    text-align: center;
    height: auto;
    // padding-top: 20px;
    overflow: auto;
    color: $text_primary3;
    @include scroll-hidden;
  }

  &__btn-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    button.base-btn {
      min-width: 80px;
    }
  }

  // 넓은 모달
  &.dialog-modal {
    padding: unset;
    min-width: 1000px;
    max-width: 1000px;
    max-height: calc(#{$full-height} - 80px);
    overflow: hidden;

    .modal-title {
      padding: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left-area {
        display: flex;
        align-items: center;

        span {
          font-size: 2.4rem;
          font-weight: bold;
        }
      }

      .right-area {
        display: flex;
      }

      &.border-bottom {
        border-bottom: 1px solid $bg_dark;
      }
    }

    .base-modal__contents {
      padding: unset;
      color: unset;
      height: calc(100% - (#{$modal-title-height} + #{$modal-btn-wrap-height}));
    }

    .base-modal__btn-wrap {
      justify-content: flex-end;
      padding: 30px;
    }

    .base-table {
      &__td {
        height: 50px;
      }
    }

    .contents-scroll {
      padding: 0 $contents-padding-left;
      max-height: 550px;
      overflow: auto;
    }

    &.small-size {
      min-width: 540px;
      max-width: 540px;
      max-height: auto;

      .body {
        max-height: 230px;
      }

      .contents-scroll {
        max-height: 300px;
      }
    }
  }
  &.small {
    width: 350px;
  }
  &.medium {
    width: 540px;
  }
  &.large {
    width: 760px;
  }
  &.xlarge {
    width: 1000px;
  }
  &.two-depth {
    width: 760px;
    max-height: calc(#{$full_height} - 140px);
    .modal-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 30px;

      .left-area {
        display: flex;
        align-items: center;
        color: $text-primary;

        span {
          font-size: 2.4rem;
          font-weight: bold;
        }
      }

      .right-area {
        display: flex;
      }

      &.border-bottom {
        border-bottom: 1px solid $bg_dark;
      }
    }
    .base-modal__btn-wrap {
      justify-content: flex-end;
    }
    .contents-scroll {
      padding: 0;
      max-height: 550px;
      overflow: auto;
    }
  }
}

.base-full-modal {
  width: 100%;
  max-width: 100%;
  min-height: $full_height;
  max-height: $full_height;
  background-color: #fff;
  animation: bottom-to-top 0.3s;
  position: relative;
  overflow: hidden;

  &__contents {
    font-size: 1.4rem;
    min-height: $full_height;
    max-height: $full_height;
    overflow: hidden;
    @include scroll-hidden;
  }
  .base-table__td {
    height: 50px;
  }
}

.base-bottom-sheet {
  min-width: 200px;
  width: 100%;
  height: auto;
  max-height: 584px;
  margin: 0 auto;
  padding: 20px 24px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #fff;
  animation: bottom-to-top 0.3s;
  overflow: hidden;
  position: relative;

  &__title {
    width: 100%;
    // height: 63px;
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2 {
      font-size: 1.8rem;
      font-weight: 600;
      // margin-top: 10px;
    }

    p {
      font-size: 1.2rem;
      padding-bottom: 2px;
      @include ellipsis;
    }

    &.with-index-title {
      display: block;
    }
  }

  &__contents {
    font-size: 1.4rem;
    text-align: center;
  }

  &__btn-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.scrollable {
    padding: 0px;

    .base-bottom-sheet__title {
      padding: 20px 24px;
    }

    .base-bottom-sheet__contents {
      overflow-y: auto;
      max-height: calc(584px - 63px);
      padding: 0 24px;
      @include scroll-hidden;
    }
  }
}

@keyframes top-to-bottom {
  from {
    opacity: 1;
    margin-top: -50px;
  }

  to {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes bottom-to-top {
  from {
    opacity: 0;
    margin-bottom: -300px;
  }

  to {
    opacity: 1;
  }
}

@keyframes modal-bg-show {
  from {
    opacity: 0;
    margin-bottom: 0;
  }

  to {
    opacity: 1;
  }
}

.workorder-select-sheet {
  text-align: left;

  .list {
    padding-bottom: 30px;

    &__item {
      // padding-top: 30px;
      display: flex;
      align-items: center;
      cursor: pointer;
      // justify-content: space-between;
    }

    &__item-wrap {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 15px 0;
    }

    &__item-info {
      width: 100%;
      max-width: 100%;
      overflow: hidden;

      p {
        font-size: 1.7rem;
        text-align: center;
        font-weight: 400;
        padding-left: 24px;
        @include ellipsis(2);
      }
    }

    &__item-default {
      width: 24px;
      min-width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;
    }

    &__item-checked {
      margin-left: 15px;
      width: 24px;
      height: 24px;
      position: relative;

      &::after {
        content: "";
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 24px;
        height: 24px;
        background-image: url("../../images/ic_check_mobile.svg");
        background-repeat: no-repeat;
        background-position: center;
      }

      &.--active::after {
        display: block;
      }
    }
  }
}

.workorder-select-manager {
  position: relative;
  $TOP_HEIGHT: 226px;
  $BOTTOM_HEIGHT: 70px;
  $CONTENTS_HEIGHT: $TOP_HEIGHT + $BOTTOM_HEIGHT;

  .sticky-top {
    position: sticky;
    top: 0;
    padding: 40px 24px 0px 24px;

    h1 {
      font-size: 2.2rem;
      font-weight: 600;
    }
  }

  .contents {
    padding: 0px 24px;
    min-height: calc(#{$full_height} - #{$CONTENTS_HEIGHT});
    max-height: calc(#{$full_height} - #{$CONTENTS_HEIGHT});
    overflow: auto;
    @include scroll-hidden;

    .empty-case {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: 1.7rem;
        font-weight: 600;
      }

      padding-top: 77px;
    }
  }

  ul {
    list-style: none;

    li {
      display: grid;
      grid-template-columns: 1fr 80px 80px 40px;
      align-items: center;
      font-size: 1.7rem;
      padding: 30px 0;
      border-bottom: 1px solid $line_dark;

      &.header {
        font-size: 1.5rem;
        font-weight: 600;
        border-bottom: 1px solid $text-primary4;
        padding-top: 40px;
        padding-bottom: 20px;
      }

      div {
        white-space: nowrap;
      }

      div:first-child {
        min-width: 74px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      div:nth-child(2) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        justify-content: center;
      }
      div:nth-child(3) {
        padding-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      div:nth-child(3) {
        width: 100%;
        padding-left: 10px;
      }

      div:last-child {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .float-bottom {
    height: $BOTTOM_HEIGHT;
    padding: 10px 24px 20px 24px;
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.workorder-images-full-modal {
  background-color: #000;
  height: $full_height;
  display: flex;
  align-items: center;
  position: relative;

  .floating-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    position: absolute;
    width: 100%;
    top: 0;
    z-index: $header-z-in;

    p {
      color: $white;
      font-size: 1.6rem;
      @include font("roboto");
    }
  }

  .drag-slider {
    max-width: 100%;
    display: flex;
    transition-property: transform;
    transition: transform 0.5s;

    &__wrap {
      display: flex;
      width: 100%;
      transition: transform 0.5s;

      img {
        width: 100%;
        object-fit: contain;
        transition: transform 0.5s;
      }
    }
  }
}

.user-base-modal {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  .user-base-modal-content {
    background-color: white;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    padding: 30px;
  }
}

.billing-schedule-info-modal {
  .summary-contents {
    padding-top: 20px;
    padding-bottom: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 16px;

    &__gray-box {
      width: 100%;
      min-width: 300px;
      height: 80px;
      background-color: $bg_light;
      border-radius: 4px;
      padding: 16px 20px;
      &.first-item {
        display: flex;
        gap: 40px;
        min-width: 620px;
        > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
      &.second-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    &__gray-box2 {
      width: 100%;
      min-width: 300px;
      height: 52px;
      background-color: $bg_light;
      border-radius: 4px;
      padding: 16px 20px;
      &.first-item {
        display: flex;
        gap: 40px;
        min-width: 620px;
        > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
      &.second-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
  ul {
    li {
      list-style: none;
      font-size: 1.4rem;
      line-height: 20px;
      letter-spacing: -0.04em;
      color: $text-primary3;
      position: relative;
      padding-left: 12px;
      margin-left: 6px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        content: "·";
        position: absolute;
        left: 0;
        font-size: 20px;
        vertical-align: middle;
        line-height: 20px;
      }
    }
  }
  .base-table-wrap {
    padding: 0;
  }
  .overflow-container {
    min-width: calc(1000px - 60px);
  }
}

.accessgroup-divice-modal {
  table {
    thead {
      th {
        border-top: 1px solid $line_light;
        border-bottom: 1px solid $line_light;
        background-color: $white;
      }
    }
  }
}

.select-buildings-contracts-modal {
  .select-buildings-contracts-table {
    .inner-tab-table {
      .body {
        width: 940px;
        height: 350px;
        overflow-y: scroll;
        overflow-x: hidden;
      }
    }
  }
  .page .base-pagination {
    height: 50px;
  }
  .contract-area {
    width: 100%;
    height: 64px;
    background-color: $bg_light;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    p.label {
      font-size: 14px;
      font-weight: 600;
      color: $text-primary3;
    }
  }
}
