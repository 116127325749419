.base-image-wrap {
}
.base-image {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 10px;
  gap: 10px;
  &__wrap {
    width: 112px;
    min-width: 112px;
    height: 112px;
    border: 1px solid $line_light;
    border-radius: 4px;
    overflow: hidden;
    // overflow:hidden;
    cursor: pointer;
    position: relative;

    .chip-case {
      position: absolute;
      top: 8px;
      left: 8px;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 3px;
      object-fit: cover;
      object-position: center;
    }
    button {
      width: 24px;
      height: 24px;
      background: url("../../images/ic_close_w.svg"), $text-primary;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16px 16px;
      position: absolute;
      top: -1px;
      right: -1px;
      border: none;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    button.detail-download {
      top: unset;
      bottom: 9px;
      right: 9px;
      background-color: $text_primary3;
      border-radius: 4px;
      background-image: url("../../images/ic_img_download_w.svg");
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-position: center;
    }
    &.form {
      .title-area {
        position: absolute;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        padding: 8px;
        display: flex;
        .not-hover {
          display: flex;
          width: 100%;
          p {
            width: fit-content;
            max-width: 100%;
            color: $white;
            font-size: 1.2rem;
            word-break: break-all;
            @include ellipsis;
          }
          span {
            color: $white;
            font-size: 1.2rem;
            white-space: nowrap;
          }
        }
        .active-hover {
          display: none;
        }
      }
      &:hover {
        .not-hover {
          display: none;
        }
        .active-hover {
          display: flex;
          width: 100%;
          p {
            width: fit-content;
            max-width: 100%;
            color: $white;
            font-size: 1.2rem;
            word-break: break-all;
            @include ellipsis;
          }
          span {
            color: $white;
            font-size: 1.2rem;
            white-space: nowrap;
          }
        }
      }
    }
    &.detail {
      .title-area {
        position: absolute;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        padding: 8px;
        display: flex;
        .not-hover {
          display: flex;
          width: 100%;
          p {
            width: fit-content;
            max-width: 100%;
            color: $white;
            font-size: 1.2rem;
            word-break: break-all;
            @include ellipsis;
          }
          span {
            color: $white;
            font-size: 1.2rem;
            white-space: nowrap;
          }
        }
        .active-hover {
          display: none;
        }
      }
      &:hover {
        .title-area {
          height: 100%;
        }
        .not-hover {
          display: none;
        }
        .active-hover {
          display: flex;
          width: 100%;
          p {
            width: fit-content;
            max-width: 100%;
            color: $white;
            font-size: 1.2rem;
            word-break: break-all;
            @include ellipsis;
          }
          span {
            color: $white;
            font-size: 1.2rem;
            white-space: nowrap;
          }
        }
      }
    }
  }

  &__empty {
    width: 112px;
    height: 112px;
    border: 1px dashed $line_light;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    button {
      width: 28px;
      height: 28px;
      border: none;
      background: url("../../images/ic_circle_plus_img.svg") no-repeat;
      background-position: center;
      background-size: contain;
      &:disabled {
        background: url("../../images/ic_circle_plus_img_disabled.svg") no-repeat;
      }
    }
  }
  &__full-screen {
    width: 100vw;
    height: $full-height;
    min-width: 1350px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100vw;
      min-width: 1350px;
      height: $full-height;
      object-fit: contain;
    }
    .img-button-wrap {
      display: flex;
      align-items: center;
      gap: 10px;
      position: absolute;
      bottom: 15px;
      z-index: 100;
      .flicker {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $text-primary;
        border-radius: 4px;
        min-width: 170px;
        height: 40px;
        padding: 5px;
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2));
        button {
          width: 30px;
          height: 30px;
          background-color: $text-primary;
          border: none;
          border-radius: 4px;
          &:hover {
            background-color: $text-primary3;
          }
          &.left {
            background-image: url("../../images/ic_chevron_down_w.svg");
            background-repeat: no-repeat;
            background-position: center;
            transform: rotate(90deg);
          }
          &.right {
            background-image: url("../../images/ic_chevron_down_w.svg");
            background-repeat: no-repeat;
            background-position: center;
            transform: rotate(-90deg);
          }
        }
        p {
          color: $white;
          font-size: 1.4rem;
        }
      }
      .download {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        background: $text-primary url("../../images/ic_download_w.svg") no-repeat center;
        border: none;
        &:hover {
          background-color: $text-primary3;
        }
      }
      button.close {
        width: fit-content;
        border-radius: 4px;
        padding: 10px 16px;
        background-color: $text-primary;
        border: none;
        height: 40px;
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2));
        color: $white;
        &:hover {
          background-color: $text-primary3;
        }
      }
    }
    .no-preview-available {
      p:first-child {
        font-size: 1.8rem;
        font-weight: 600;
        color: $white;
        text-align: center;
      }
      p:nth-child(2) {
        font-size: 1.5rem;
        font-weight: 400;
        text-align: center;
        color: $white;
      }
    }
    p.file-name {
      position: absolute;
      top: 24px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 1.5rem;
      font-weight: 600;
      text-align: center;
      color: $line_light;
    }
  }
}
