* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}
html {
  font-size: 62.5%; //10px;
  font-family: "Apple SD Gothic Neo", "Roboto", sans-serif;
  width: 100%;
  height: 100%;
  color: $text-primary;
  word-break: break-all;

  .mobile {
    min-width: unset;
  }
}
// 최종 윈도우 max-width
.base-pc-fix {
  min-width: 1350px;
}
// 루트 클래스 (error)
.base-pc-error {
  .contents-container {
    padding-left: 0;
  }
}
.table-cursor-unset:hover {
  cursor: unset;
  background-color: transparent;
  .base-table__td {
    background-color: transparent !important;
  }
}

#root {
  height: 100%;
}

// #webpack-dev-server-client-overlay {
//   display: none !important; // 빌딩 리스트 타입 문제로 임시로 설정
// }
body {
  background-color: $white;
  margin: 0;
  padding: 0;
  width: 100%;
  height: $full-height;
  @include scroll-hidden;
  // overflow-y: hidden;
  // overflow:hidden;
  // @include mobile {
  //   padding: 0;
  //   margin: 0;
  //   height: 100%;
  //   background-color: #fafafa;
  //   width: 100%;
  //   overflow: hidden;
  //   font-family: "Apple SD Gothic Neo", sans-serif;
  //   word-break: break-all;
  //   font-size: 1.4rem;
  //   -webkit-tap-highlight-color: transparent;
  //   color: $text-primary;
  // }
}

// ::-webkit-scrollbar{
// }
// ::-webkit-scrollbar-thumb{
//   background-color: transparent;
// }
// ::-webkit-scrollbar-track{
//   background-color: #fff;
// }
.App {
  height: $full-height;
  max-height: $full-height;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: unset;
}

.h_2 {
  font-size: 2em;
}
a {
  text-decoration: none;
  color: unset;
  &.text-underline {
    color: $system_linked;
    text-decoration: underline;
    &:hover {
      opacity: 0.6;
    }
  }
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-semibold {
  font-weight: 600 !important;
}
.font-weight-medium {
  font-weight: 500 !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100% !important;
}
.h-input-validation {
  min-height: 58px;
}
.w-fit {
  width: fit-content;
}
.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-flex {
  display: flex !important;
}
.flex-1 {
  flex: 1;
}
.flex-baseline {
  display: flex;
  align-items: baseline;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-start {
  display: flex;
  align-items: flex-start !important;
}
.flex-start-start {
  display: flex;
  align-items: flex-start !important;
  justify-content: start !important;
}
.flex-center {
  display: flex;
  align-items: center;
}
.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-center-end {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
}
.flex-center-start {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
}
.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}
.justify-contents-center {
  justify-content: center !important;
}
.justify-contents-start {
  justify-content: flex-start !important;
}
.justify-contents-end {
  justify-content: flex-end !important;
}
.justify-contents-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right !important;
}
.text-underline {
  text-decoration: underline;
  position: relative;
  &.target-blank {
    display: inline;
    vertical-align: middle;
    // padding-right:16px;
    //   &::after{
    //     content:'';
    //     display:block;
    //     position:absolute;
    //     right:0px;
    //     top:50%;
    //     transform:translateY(-50%);
    //     width:16px;
    //     height:16px;
    //     background: url('../../images/ic_target_blank_g.svg') no-repeat center;
    // }
  }
}

.ic-target-blank {
  display: inline-block;
  min-width: 18px;
  max-width: 18px;
  width: 18px;
  height: 18px;
  background: url("../../images/ic_target_blank_g.svg") no-repeat center;
  vertical-align: middle;
}
.text-line {
  text-decoration: line-through;
}
.pre-discount {
  text-decoration: line-through;
  color: map-get($grays, "500");
}
.no-wrap {
  white-space: nowrap;
}
.break-all {
  word-break: break-all;
}

.keep-all {
  word-break: keep-all;
}

.pre-formatted {
  white-space: pre-wrap;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-unset {
  cursor: unset !important;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.overflow-visible {
  overflow: visible !important;
}
.overflow-scroll {
  overflow-y: auto !important;
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

// 1줄 ... 처리
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.h-36 {
  min-height: 36px;
}

.lh-18 {
  line-height: 1.8;
}
.lh-20 {
  line-height: 20px;
}
.lh-22 {
  line-height: 22px;
}

// 2줄 ... 처리
.ellipsis2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  //필요에 따라 line-height를 써야할 수 있다.
  word-break: break-all;
}
.text-color-transparent {
  color: transparent;
}

.required {
  position: relative;
  &::before {
    content: "*";
    color: map-get($reds, "900");
    font-size: 1.4rem;
    left: -8px;
    top: -2px;
    position: absolute;
  }
}

.fit-contents {
  width: fit-content;
}

.not-available {
  color: map-get($grays, "300") !important;
  .contents-container__grid-index {
    color: map-get($grays, "300") !important;
  }
}

.border-gray {
  border: 1px solid map-get($grays, "200");
}

.border-bottom-gray {
  border-bottom: 1px solid map-get($grays, "200");
}

.border-none {
  border: none !important;
}

.canvas {
  width: 30px;
  height: 30px;
  overflow: hidden;
  display: block;
}

.base-markdown {
  font-size: 1.5rem;
  white-space: initial;

  .toastui-editor-contents {
    ol > li::before {
      font-size: 1.5rem;
      margin-top: 2px;
      color: $text_primary;
    }
    ul > li::before {
      margin-top: 11px;
      background-color: $text_primary;
    }
    h1 {
      color: $text_primary;
      span {
        font-size: 2.4rem !important;
        line-height: 2.8rem !important;
      }
    }
    h2 {
      color: $text_primary;
      span {
        font-size: 2.2rem !important;
        line-height: 2.3rem !important;
      }
    }
    h3 {
      color: $text_primary;
      span {
        font-size: 2rem !important;
      }
    }
    h4 {
      color: $text_primary;
      span {
        font-size: 1.8rem !important;
      }
    }
    h5 {
      color: $text_primary;
      span {
        font-size: 1.6rem !important;
      }
    }
    h6 {
      color: $text_primary;
      span {
        font-size: 1.4rem !important;
      }
    }
    p {
      font-size: 1.5rem !important;
      color: $text_primary;
      span {
        color: $text_primary;
      }
    }
    table {
      color: $text_primary;
    }
  }
}

.bottom-divider {
  position: relative;
  margin-bottom: 10px;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    bottom: -10px;
    height: 10px;
    background-color: $bg_dark;
    left: 0;
  }
}
.top-divider {
  position: relative;
  margin-top: 1px;
  &::before {
    content: "";
    position: absolute;
    width: calc(100% + 60px);
    top: -1px;
    height: 1px;
    background-color: $bg_dark;
    left: -30px;
  }
}

.fixed-bottom-mobile {
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 500px;
  background-color: $white;
  padding: 10px 24px 20px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  &.top-shadow {
    box-shadow: 0px -1px 5px 0px #0000001a;
  }
}

.error-icon {
  width: 62px;
  height: 62px;
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 30px;
  &.ic-exclamation {
    background-image: url("../../images/ic_circle_exclamation_mark.svg");
  }
  &.ic-system {
    background-image: url("../../images/ic_circle_system.svg");
  }
}

.grecaptcha-badge {
  @include desktop {
    display: none !important; // PC에서 임시로 보이지않게 처리
  }
}
.scroll-hidden {
  @include scroll-hidden;
}

.base-scroll {
  width: 100%;
  overflow: auto;

  position: relative;
}

// 파일 자동 랩처리되는 파일목록
.flex-files {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  &__wrap {
    display: flex;
    align-items: center;
    .delete-btn-x {
      width: 20px;
      height: 20px;
      background-image: url("../../images/ic_close.svg");
      background-size: contain;
      cursor: pointer;
    }
    .ellipsis {
      max-width: 200px;
    }
  }
}

.list-none {
  list-style: none;
}

.small-bullet li::marker {
  font-size: 8px;
}

ul.base-list {
  li {
    list-style: none;
    font-size: 1.2rem;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: $text-primary3;
    position: relative;
    padding-left: 12px;
    margin-left: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      content: "";
      width: 3px;
      height: 3px;
      border-radius: 8px;
      background-color: $text_primary3;
      position: absolute;
      left: 0;
      top: 9px;
      // font-size: 20px;
      // vertical-align: middle;
      // line-height: 20px;
    }
  }
}

.base-file {
  &__list {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &__download {
    display: block;
    width: 20px !important;
    height: 20px;
    background-image: url("../../images/ic_download_g.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  &__remove {
    width: 20px;
    height: 20px;
    border: none;
    background-color: transparent;
    background-image: url("../../images/ic_trash_g.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  .filename {
    width: fit-content;
    max-width: 310px;
    overflow: hidden;
    display: block !important;
    @include ellipsis;
  }
}

// .ic-file {
//   min-width: 20px;
//   width: 20px;
//   height: 20px;
//   background-image: url("../../images/ic_file.svg");
//   background-position: center;
//   background-size: 20px;
//   background-repeat: no-repeat;
// }

.ic-file {
  width: 20px;
  height: 20px;
  background-image: url("../../images/ic_file.svg");
  background-size: contain;
  cursor: pointer;

  &:hover {
    background-image: url("../../images/ic_file_b.svg");
  }
}

.ic-chevron-right {
  width: 10px;
  height: 18px;
  display: inline-block;
  min-width: 10px;
  max-width: 10px;
  background: url("../../images/ic_chevron_right_g.svg") no-repeat center;
  vertical-align: middle;
}

.two-depth-link {
  .delete-x-btn {
    display: inline-block;
    vertical-align: middle;
  }
}

.delete-btn-x {
  width: 20px;
  height: 20px;
  background-image: url("../../images/ic_close.svg");
  background-size: contain;
  cursor: pointer;
}

.ic-link {
  width: 20px;
  height: 20px;
  background-image: url("../../images/ic_link.svg");
  background-size: contain;
  cursor: pointer;

  &:hover {
    background-image: url("../../images/ic_link_b.svg");
  }
}

.list-none {
  list-style: none;
}
