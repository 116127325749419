.price-page {
  .gray-box {
    width: 775px;
    padding: 20px;
    background: $bg_light;
    border-radius: 10px;
    display: grid;
    // row-gap: 20px;
    &__grid {
      display: grid;
      grid-template-columns: 145px 1fr;
      &-index {
        display: flex;
        align-items: center;
        font-size: 1.4px;
        color: $text-primary3;
      }
      &-contents {
        width: 100%;
      }
    }
  }
  .custom-time-wrap {
    .base-input input {
      padding-right: 10px !important;
    }
  }
}
