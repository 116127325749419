.memo {
  .textarea-warp {
    position: relative;
    .now-max-length {
      display: none;
    }
  }
  .textarea-warp .ic-file-warp {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  textarea {
    overflow-y: hidden;
    min-height: 20px;
    padding-right: 40px;
  }
  .add-file {
    textarea {
      padding-bottom: 145px;
    }
  }
  .bottom-wrap {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;
  }
  .bottom-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 10px;
  }

  .list-title-warp {
    border-bottom: 0.5px solid #dadada;
  }

  .memo-row {
    display: flex;
    flex-direction: row;

    gap: 10px;
    border-bottom: 0.5px solid #dadada;
    min-height: 60px;
  }

  .innerUrl {
    a {
      color: #4d5edf;
    }
  }

  .memo-row ~ .input-warp {
    padding: 20px 0;
    border-bottom: 0.5px solid #dadada;
  }

  .linkWarp {
    .ic-link {
      display: none;
    }

    &:hover .ic-link {
      display: block;
    }
  }
}
