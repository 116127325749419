.contract-reservation-page {
  .reservation-wrap {
    border-bottom: 1px solid $bg_dark;
    padding: 30px 30px 30px 30px;
    &__title {
      display: flex;
      align-items: center;
      h3 {
        width: 150px;
        font-size: 1.8rem;
        font-weight: 600;
        height:30px;
      }
    }
    &__gray-board {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      background-color: $bg_light;
      border-radius: 10px;
      & > div {
        padding: 30px 100px;
        b{
            font-weight:500;
        }
        p.index {
          font-weight: 600;
          font-size: 1.4rem;
          color: $text_primary3;
          margin-bottom:8px;
        }
        .value {
          font-size: 1.8rem;
        }
      }
    }
  }
}
