.account-table {
  .inner-table {
    thead {
      // border-top: 1px solid #f6f6f9;
      border-bottom: 1px solid #f6f6f9;

      tr {
        th {
          background-color: white;
        }
      }
    }
    tbody {
      // border-bottom: 1px solid #f6f6f9;
      tr {
        border: none;
        // border-top: 1px solid #f6f6f9;

        // border-bottom: none;
      }
    }

    td {
      // padding: 10px 0;
      height: 50px;
    }
  }
}
