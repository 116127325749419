.base-toggle {
  position: relative;
  display: inline-block;
  width: fit-content;
  input {
   display:none;
    &:checked + .base-toggle__slider {
      background-color: $text-primary;
      border-color: $text-primary;
    }
    &:focus + .base-toggle__slider {
      box-shadow: 0 0 1px $text-primary;
    }
    &:checked + .base-toggle__slider::before {
      transform: translateX(22px);
    }
    &:checked + .base-toggle__label{
      color:$text-primary
    }
  }
  &__slider {
    position: relative;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width:52px;
    height:30px;
    background-color: $line_light;
    border:1px solid $line_dark;
    transition: 0.4s;
    border-radius: 30px;
    &::before {
      position: absolute;
      content: "";
      height: 22px;
      width: 22px;
      border-radius: 20px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.30);
      transition: 0.4s;
    }
  }
  &__label{
    margin-left:48px;
    font-size:1.4rem;
    cursor: pointer;
    white-space: nowrap;
    color:map-get($grays, "500");
    display:flex;
    height:24px;
    padding-bottom:1px;
    align-items: center;
  }
}
