.page-dashboard {
  section {
    padding-bottom: 30px;
  }
  .dashboard-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, 154px);
    grid-auto-rows: 100px;
    &__card {
      width: 100%;
      height: 100%;
      background-color: $bg_light;
      border: 1px solid $bg_dark;
      border-radius: 8px;
      padding: 20px;
      font-size: 1.4rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &.text-white p.title,
      &.text-white p.value {
        color: #fff !important;
      }
      p.title {
        position: relative;
        color: $text-primary;
        font-size: 1.4rem;
        font-weight: 400;
      }
      p.value {
        position: relative;
        color: $text-primary;
        font-size: 1.6rem;
        font-weight: 500;
      }
    }
  }
  .dashboard-bar {
    background-color: $bg_light;
    border-radius: 8px;
    display: grid;
    grid-template-columns: repeat(8, 132px);
    min-height: 50px;
    margin-bottom: 20px;
    &__box {
      margin: 15px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid $line-light;
      &:last-child {
        border-right: none;
      }
      p:first-child {
        font-size: 1.4rem;
        color: $text-primary;
        white-space: nowrap;
      }
      span {
        margin-left: 10px;
        font-size: 1.4rem;
        color: $system_linked;
        text-decoration: underline;
      }
    }
  }
  .dashboard-section-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, 342px);
    &__box {
      min-height: 170px;
      border: 1px solid $line-light;
      border-radius: 8px;
      > div:first-child {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 20px;
        border-bottom: 1px solid $line-light;
        p {
          font-size: 1.4rem;
          font-weight: 600;
        }
        span {
          display: block;
          font-size: 1.2rem;
          font-weight: 500;
          color: $text-primary3;
          padding-right: 14px;
          min-height: 16px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            right: 0;
            width: 14px;
            height: 14px;
            background: url("../../images/ic_chevron_down_b.svg") no-repeat;
            top: 3px;
            transform: rotate(-90deg);
          }
        }
      }
      > div:last-child {
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        .contents-wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            font-size: 1.4rem;
          }
          span {
            font-size: 1.2rem;
            color: $text-primary3;
          }
        }
      }
      .no-data {
        width: 100%;
        height: 117px;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          font-size: 1.4rem;
        }
      }
    }
  }
}
