.table-select-modal {
    padding: 30px;
    padding-bottom: 0px;

    &__title {
        display: flex;
        width: 100%;
        align-items: center;

        justify-content: space-between;

        margin-bottom: 30px;

        span {
            font-size: 2.4rem;
            font-weight: 700;
            text-align: center;
        }
    }

    &__btn-wrap {
        display: flex;
        width: 100%;
        align-items: center;

        justify-content: flex-end;

        span {
            font-size: 1.8rem;
            font-weight: 600;
            text-align: center;
        }
    }

    .table-select-modal-bottom {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        flex: 1;

        .page {
            width: 100%;
            position: relative;
        }

        .buttons {
            display: flex;
            align-items: center;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
        }
    }

    // &__contents {
    //     height: 380px;
    //     overflow-y: scroll;
    // }
}