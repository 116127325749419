// AI 데이터 분석 아이콘
.ic-chart-dot {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url("../../images/chart-dot.svg") no-repeat center;
  background-size: contain;
}

// .gp4 {
//   gap: 4px;
// }

// .flex-row {
//   display: flex;
//   flex-direction: row;
// }

// .align-center {
//   align-items: center;
// }

.bg-system-linked {
  background-color: $system_linked;
}

.ai-data-analysis-contents-wrap {
  min-height: 326px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  &__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    p {
      font-size: 14px;
    }
  }

  &__result {
    font-size: 14px;
    h2 {
      font-weight: 600;
      font-size: 16px;
    }
    p {
      font-weight: 400;
      span {
        color: $system_linked;
        font-weight: 600;
      }
    }
  }

  &__error {
    font-size: 16px;
  }
}

.report-title-wrap {
  flex: 1 !important;

  .created {
    font-size: 14px;
    color: #767676;
  }
  > .title-wrap {
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: start;
    font-size: 24px;
    font-weight: 700;

    span {
      font-size: 10px;
      font-weight: 700;
      color: $system_linked;
      margin-left: 4px;
    }
  }

  .reporting-period {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 16px;

    > span {
      font-weight: 700;
    }
    p {
      font-weight: 400;
      span {
        font-size: 14px;
        color: #767676;
        margin-left: 8px;
      }
    }
  }
}

.free-icon-pdf {
  width: 24px;
  height: 24px;
  background: url("../../images/free-icon-pdf.svg") no-repeat center;
  background-size: contain;
  background-position: center;
}

.logo_AI {
  width: 102.84px;
  height: 30px;
  background: url("../../images/ai_logo_ctrlroom_b.svg") no-repeat;
  background-size: contain;
  background-position: center;
}

.icon-chart {
  width: 16px;
  height: 16px;
  background: url("../../images/icon-chart.svg") no-repeat;
  background-size: contain;
  background-position: center;
}

.icon-flash {
  width: 16px;
  height: 16px;
  background: url("../../images/icon-flash.svg") no-repeat;
  background-size: contain;
  background-position: center;
}

.icon-pattern {
  width: 16px;
  height: 16px;
  background: url("../../images/icon-pattern.svg") no-repeat;
  background-size: contain;
  background-position: center;
}

.icon-warning {
  width: 16px;
  height: 16px;
  background: url("../../images/Triangle_Warning.png") no-repeat;
  background-size: contain;
  background-position: center;
}

.icon-suggestion {
  width: 16px;
  height: 16px;
  background: url("../../images/icon-suggestion.svg") no-repeat;
  background-size: contain;
  background-position: center;
}

.report-content {
  // PDF 생성을 위한 스타일 추가
  background-color: #ffffff;

  .report-section {
    .title-block {
      display: flex;
      justify-content: start;
      align-items: center;
      flex-direction: row;
      gap: 8px;
      h2 {
        font-size: 18px;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    .report-block {
      border-radius: 4px;
      padding: 12px;
      border: #e0e0e0 1px solid;
      p {
        // color: #666;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

// PDF 생성 관련 스타일
.pdf-only {
  position: fixed;
  left: -9999px;
  top: -9999px;

  .pdf-content {
    background-color: #ffffff;
    width: 210mm; // A4 width
    min-height: 297mm; // A4 height
    padding-bottom: 30px;
  }
}
