// base layout (PC)
.base-layout-container {
  width: 100%;
  height: calc(100% - #{$search-wrap-height});
  margin: 0 auto;
  overflow: hidden;

  &.mobile {
    height: 100%;
    overflow: auto;
    @include scroll-hidden;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  &.full-case {
    margin: unset;
    max-width: unset;
    overflow: scroll;
  }
  .base-layout {
    width: 100%;
    height: auto;
    min-height: 100%;
    display: flex;
    background-color: #fff;
    flex-direction: column;
    justify-content: space-between;
    .base-layout-contents {
      width: 100%;
      height: auto;
      background-color: #fff;
    }
  }
  @include mobile {
    width: 100%;
    height: 100%;
    // max-width: 500px;
    margin: 0 auto;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    &.full-case {
      margin: unset;
      max-width: unset;
      overflow: scroll;
    }
    .base-layout {
      width: 100%;
      height: auto;
      min-height: 100%;
      display: flex;
      background-color: #fff;
      flex-direction: column;
      justify-content: space-between;
      .base-layout-contents {
        width: 100%;
        height: auto;
        background-color: #fff;
      }
    }
  }
}

.contents-container {
  width: 100%;
  padding-left: $sidebar-width;
  height: calc(#{$full-height} - #{$gnb-height});

  // overflow:hidden;
  & > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .fit-start-line {
    margin-top: 10px;
  }
  &__divide-top {
    position: relative;
    padding-top: 30px;
    margin-bottom: 30px;
    &::after {
      content: "";
      position: absolute;
      width: calc(100% + #{$contents-padding-right + $contents-padding-left});
      height: 8px;
      top: 0px;
      background-color: map-get($grays, "100");
      left: -$contents-padding-left;
    }
  }
  &__divide-bottom {
    //특이케이스 사용한곳 = 공간상품 > 공용공간
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;
    &::after {
      content: "";
      position: absolute;
      width: calc(100% + #{$contents-padding-right + $contents-padding-left});
      height: 8px;
      bottom: 0px;
      background-color: map-get($grays, "100");
      left: -$contents-padding-left;
    }
  }
  &__1070 {
    max-width: 1070px;
  }
  &__table {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  &__scroll {
    width: 100%;
    height: calc(#{$full-height} - #{$gnb-and-title-and-btnwrap});
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    flex-shrink: 1;
    flex-grow: 1;
    position: relative;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__wrap {
    height: auto;
    position: relative;
    &-article {
      // subtitle을 1개 포함하고있는 하나의 아티클
      position: relative;
      padding: 30px;
      max-width: 1130px;
      &::before {
        content: "";
        position: absolute;
        width: $contents-width;
        top: -1px;
        height: 1px;
        background-color: $bg_dark;
        left: 0px;
      }
      &:first-child::before {
        content: unset;
        display: none;
      }
      &.full-width {
        max-width: 100%;
      }
    }
    &-contents {
      padding: 30px;
    }
    section.contents-container__divide-section:first-child {
      padding-top: 0;
    }
  }
  // 그리드 contents 상세에는 p(패딩탑있음), 등록수정에는 div 와야함
  &__grid {
    display: grid;
    width: 100%;
    grid-template-columns: 145px 1fr;
    min-height: 40px;
    margin-bottom: 10px;
    max-width: 1070px;
    &-index {
      padding-top: 10px;
      p {
        font-size: 1.4rem;
        position: relative;
        color: $text_primary3;
        font-weight: 600;
        &.required {
          padding-left: 9px;
        }
        &.required::before {
          content: "*";
          color: map-get($reds, "900");
          font-size: 1.4rem;
          left: 0px;
          top: -2px;
          position: absolute;
        }
      }
    }
    &-contents {
      > p {
        padding-top: 10px;
      }

      p,
      span,
      a {
        font-size: 1.4rem;
      }
      a {
        cursor: pointer;
        display: flex;
        width: fit-content;
        align-items: flex-end;
      }
    }
    &-contents > div:first-child {
      // padding-top:6px;
      min-height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__sub-title {
    // 페이지 내의 서브 타이틀(ex: 회원 이용제한내역)
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    max-width: 1070px;

    h2 {
      color: $text-primary;
      font-size: 1.8rem;
      font-weight: 600;
      height: 26px;
      display: flex;
      align-items: center;
      width: 100%;
      .ic-link {
        display: none;
      }
      &:hover {
        .ic-link {
          margin-top: 1px;
          display: block;
        }
      }
    }
    &.flex-column {
      flex-direction: column;
      align-items: start;
      .contents-container__sub-title-info {
        margin-left: 20px;
        margin-bottom: 16px;
      }
    }
    &-info {
      font-size: 1.2rem;
      color: map-get($grays, "500");
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        background: url("../../images/ic_info.svg");
        left: -22px;
        top: 50%;
        transform: translateY(-42%);
        opacity: 0.5;
      }
    }
  }
  &__search-wrap {
    width: 100%;
    height: auto;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: $contents-padding-left;
    padding-right: $contents-padding-right;
    padding-top: 20px;
    padding-bottom: 20px;

    .left-area {
      section {
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: unset;
        }
        .left-area__contents > div {
          margin-right: 10px;
        }
      }
      &__index {
        height: 100%;
        display: flex;
        align-items: flex-start;
        padding-top: 10px;
        span {
          font-size: 1.3rem;
          margin-right: 20px;
          white-space: nowrap;
        }
      }
      &__contents {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        row-gap: 10px;
      }
    }
    .right-area {
      width: fit-content;
      height: 100%;
      padding-left: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
    }
    .base-input {
      input {
        &:read-only {
          background-color: $white;
        }
        display: block;
        width: 100%;
        @include ellipsis;
      }
    }
  }
  &__btn-wrap {
    height: $btn-wrap;
    width: 100%;
    padding-left: $contents-padding-left;
    padding-right: $contents-padding-right;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    z-index: $btn-wrap-z-in;
    .left-area {
      display: flex;
      align-items: center;
    }
    .right-area {
      display: flex;
      align-items: center;
    }
  }
  &__edit-area {
    position: absolute;
    right: 30px;
    top: 30px;
  }
  .image-scroll {
    max-width: calc(100vw - 260px);
    overflow-x: auto;
    display: flex;
    align-items: flex-start;
    $default-height: 182px;
    $case2-height: 232px;
    $case-only-img: 134px;

    &::-webkit-scrollbar {
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
      height: 10px;
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e0e0e0;
      border-radius: 100px;
    }

    .image-container {
      width: 178px;
      min-width: 178px;
      max-width: 178px;
      height: $default-height;
      &.two-input-case {
        height: $case2-height;
      }
      &.only-img {
        height: $case-only-img;
      }
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
      &__img-wrap {
        border: 1px solid map-get($grays, "400");
        height: $case-only-img;
        background-color: gray; //tem
        position: relative;
        border-radius: 4px;
        overflow: hidden;
        .index-number {
          font-size: 1.3rem;
          position: absolute;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 2px;
          background-color: map-get($grays, "900");
          color: $white;
          border-bottom-right-radius: 4px;
        }
      }
      &:hover {
        .image-container__erase-btn {
          display: block;
        }
      }
      &__img {
        width: 100%;
        height: 100%;
      }
      &__erase-btn {
        display: none;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        background: url("../../images/ic_close_w.svg") no-repeat $erase-btn;
        background-size: 16px;
        background-position: center;
        cursor: pointer;
        border: 0;
        right: 8px;
        top: 8px;
        position: absolute;
      }
      .chip-case {
        position: absolute;
        bottom: 8px;
        left: 8px;
      }
      &.empty {
        border: 1.5px dashed map-get($grays, "400");
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.two-input-case {
          height: $case2-height;
        }
      }
      &.detail {
        height: fit-content;
        .desc-wrap {
          max-width: 178px;
        }
      }
      &__plus-btn {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: map-get($grays, "900");
        border: none;
        background-image: url("../../images/ic_plus_w.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 18px;
        cursor: pointer;
      }
    }
  }
  .index-tab {
    font-weight: 600;
    color: map-get($grays, "700");
    background-color: map-get($grays, "200");
    width: fit-content;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 4px 12px;
    span {
      font-size: 12px;
    }
  }
}

.index-top-padding {
  padding-top: 10px;
}

.sub-title-info {
  font-size: 1.2rem;
  color: map-get($grays, "500");
  position: relative;
  text-align: left;
  &::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    background: url("../../images/ic_info.svg");
    left: -22px;
    top: 50%;
    transform: translateY(-42%);
    opacity: 0.5;
  }
}
