.loading-spinner {
  display: inline-block;
  animation: rotate 1s linear infinite;
  transform-origin: center center;
  will-change: transform;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  svg {
    display: block;
  }
}
