.error-page {
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  .error-message-wrap {
    padding-top: 30px;
    h2 {
      font-size: 1.8rem;
      font-weight: 600;
      margin-bottom: 10px;
    }
    p {
      font-size: 1.5rem;
      line-height: 22px;
    }
    & ~ .error-message-wrap {
      padding-top: 40px;
    }
  }
  .error-icon {
    width: 62px;
    height: 85px;
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    &.ic-question {
      background-image: url("../../images/ic_underline_question.svg");
    }
  }
}
