.image-preview-area {
  width: 320px;
  height: 400px;
  outline: 1px solid map-get($grays, "400");
  box-sizing: border-box;
  position: relative;

  img {
    width: 320px;
    height: 400px;
    object-fit: contain;
    object-position: 50% 50%;
    opacity: 0.5;
  }
  &.responsive {
    width: fit-content;
    height: 100%;
    max-width: calc(100vw - 400px);
    max-height: calc(100vh - 400px);
    overflow: auto;
    @include scroll-hidden;
    img {
      width: unset;
      height: unset;
      display: block;
    }
  }
  .desc-comp {
    width: 48px;
    height: 31px;
    font-size: 1.3rem;
    border: 1px solid map-get($grays, "900");
    background-color: map-get($grays, "900");
    color: $white;
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mgmt-office {
  &__table {
    th {
      background-color: white;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
    }
  }
}

.map-navigation-list {
  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    span {
      font-size: 14px;
      display: block;
      width: 65px;
      margin-right: 10px;
    }
  }
}

.sub-tab {
  // mt20 px30 border-bottom
  padding: 20px 30px 0;

  ul {
    display: flex;
    align-items: center;

    li {
      font-size: 15px;
      font-weight: 400;
      // border: 1px solid gray;
      border-bottom: none;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      margin-right: 3px;
      // padding: 10px;
      button {
        border: none;
        background-color: transparent;
        width: 100%;
        padding: 10px;
      }
    }
  }
}

// .building-page {
// }

.sub-title-banner {
  font-size: 14px;
  padding: 8px 12px;
  // background-color: #f1f1f1;
  background-color: #f1f1f1;
  border-radius: 20px;
}
