.page-monitoring {
  // $table-width: 1200px;
  $floor-cell-width: 100px; //층 셀 너비
  $reservation-cell-width: 145px; //회의실 셀 너비
  $access-device-width: 100px; // 출입기기 셀 너비
  $room-number-width: 100px; // 재실 셀 너비
  $indoor-unit-width: 140px; // 실내기 셀 너비
  $average-temp-width: 100px; // 평균온도 셀 너비
  $lighting-width: 100px; //조명 셀너비
  $toilet-male-width: 100px; // 남자화장실 셀 너비
  $toilet-female-width: 100px; // 여자화장실 셀 너비
  $cctv-width: 100px; // CCTV 셀 너비
  $crowded-width: 160px; // 혼잡도 셀 너비

  // 기본 너비 계산 (CCTV, 혼잡도 제외)
  $table-width: $floor-cell-width + $reservation-cell-width + $access-device-width + $room-number-width + $indoor-unit-width + $average-temp-width +
    $lighting-width + $toilet-male-width + $toilet-female-width;

  .br-hidden {
    border-right-color: $white !important;
  }
  .table-label {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: $table-width;
    &__info {
      display: flex;
    }
    &__refresh {
      display: flex;
      align-items: center;
      gap: 10px;
      > p {
        font-size: 13px;
      }
      button {
        padding-left: 34px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 16px;
          height: 16px;
          background-image: url("../../images/ic_refresh.svg");
          left: 10px;
        }
      }
    }
  }

  .ic-default {
    width: 16px;
    height: 16px;
    background-size: contain;
    cursor: pointer;
  }

  .ic-man-b {
    @extend .ic-default;
    background-image: url("../../images/ic_man_b.svg");
  }

  .ic-man-g {
    @extend .ic-default;
    background-image: url("../../images/ic_man_g.svg");
  }

  .ic-woman-b {
    @extend .ic-default;
    background-image: url("../../images/ic_woman_b.svg");
  }

  .ic-woman-g {
    @extend .ic-default;
    background-image: url("../../images/ic_woman_g.svg");
  }

  .table-monitoring {
    display: block;
    width: $table-width;
    max-width: $table-width;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 225px); /* 전체높비에서 뺀 나머지 */
    border: 1px solid $line_light;
    border-collapse: collapse;
    &__header {
      display: table;
      // width: 100%;
      position: sticky;
      top: 0;
      z-index: 1;
      height: 50px;
      background-color: $white;
      border-bottom: 1px solid $line_light;
      th:first-child {
        width: $floor-cell-width;
        min-width: $floor-cell-width;
        max-width: $floor-cell-width;
      }
      th:nth-child(2) {
        width: $reservation-cell-width;
        min-width: $reservation-cell-width;
        max-width: $reservation-cell-width;
      }
      th:nth-child(3) {
        width: $access-device-width;
        min-width: $access-device-width;
        max-width: $access-device-width;
      }
      th:nth-child(4) {
        width: $room-number-width;
        min-width: $room-number-width;
        max-width: $room-number-width;
      }
      th:nth-child(5) {
        width: $indoor-unit-width;
        min-width: $indoor-unit-width;
        max-width: $indoor-unit-width;
      }
      th:nth-child(6) {
        width: $average-temp-width;
        min-width: $average-temp-width;
        max-width: $average-temp-width;
      }
      th:nth-child(7) {
        width: $lighting-width;
        min-width: $lighting-width;
        max-width: $lighting-width;
      }
      th:nth-child(8) {
        width: $toilet-male-width;
        min-width: $toilet-male-width;
        max-width: $toilet-male-width;
      }
      th:nth-child(9) {
        width: $toilet-female-width;
        min-width: $toilet-female-width;
        max-width: $toilet-female-width;
      }

      th:nth-child(10) {
        width: $cctv-width;
        min-width: $cctv-width;
        max-width: $cctv-width;
      }
      th:nth-child(11) {
        width: $crowded-width;
        min-width: $crowded-width;
        max-width: $crowded-width;
      }
      p {
        font-size: 14px;
        font-weight: 600;
      }
    }
    &__body {
      display: block;
      // overflow-y: auto;
      // overflow-x: hidden;
      max-width: 1200px;
      // max-height: calc(100vh - 275px); /* 전체높비에서 뺀 나머지 */
      tr:last-child {
        .floor-td {
          border-bottom: none;
        }
        td {
          border-bottom: none;
        }
      }
      &-floor {
        td {
          &:first-child {
            border-right: 1px solid $line_light;
          }
          border-bottom: 1px solid $line_light;
          font-size: 14px;
          vertical-align: top;
        }
        &:nth-child(odd) {
          background-color: $bg_light;
        }
        .floor-cell {
          padding: 20px;
          display: block;
          min-width: $floor-cell-width;
          height: 100%;
          p {
            width: 100%;
            text-align: center;
          }
        }
        .cell {
          padding: 20px;
          border-right: 1px solid $line_light;
          border-bottom: 1px solid $line_light;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 4px;
          min-height: 60px;
          &:nth-child(1) {
            width: $reservation-cell-width;
            min-width: $reservation-cell-width;
          }
          &:nth-child(2) {
            width: $access-device-width;
            min-width: $access-device-width;
          }
          &:nth-child(3) {
            width: $room-number-width;
          }
          &:nth-child(4) {
            width: $indoor-unit-width;
          }
          &:nth-child(5) {
            width: $average-temp-width;
          }
          &:nth-child(6) {
            width: $lighting-width;
          }
          &:nth-child(7) {
            width: $toilet-male-width;
          }
          &:nth-child(8) {
            width: $toilet-female-width;
          }
          &:nth-child(9) {
            width: $cctv-width;
          }
          &:nth-child(10) {
            width: $crowded-width;
            border-right: none;
          }
        }
      }
      &-reservation {
        display: flex;
        &:last-child {
          .cell {
            border-bottom: none;
          }
        }
      }
    }

    &.has-cctv {
      width: $table-width + $cctv-width + $crowded-width;
      max-width: $table-width + $cctv-width + $crowded-width;
    }

    &:not(.has-cctv) {
      width: $table-width;
      max-width: $table-width;
    }
  }
}
