button {
  font-family: "Apple SD Gothic Neo", sans-serif;
  letter-spacing: -0.04em;
  color: $text-primary;
  cursor: pointer;
}

.base-btn-mobile {
  width: 100%;
  height: 54px;
  white-space: nowrap;
  position: relative;
  font-size: 1.7rem;
  font-weight: 600;
  background-color: $text-primary;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  color: $white;
  line-height: 16px;
  @include disabled-btn;
  &.color-white {
    background-color: $white;
    border: 1px solid $line_dark;
    color: $text-primary;
    &:disabled {
      background-color: $bg_light;
      border: 1px solid $line_dark;
      color: $text-primary4;
    }
  }
  &.color-green {
    background-color: $system_green;
    color: $white;
  }
  &.color-gray {
    background-color: $text-primary4;
    color: $white;
  }
  &.size-small {
    height: 40px;
    font-weight: 400;
  }
  &.size-medium {
    height: 43px;
  }
  .float-right-text {
    position: absolute;
    right: 20px;
    font-size: 1.5rem;
    text-decoration: underline;
    font-weight: 400;
  }
  &:disabled {
    background-color: $text-primary4;
  }
}

.base-chip-mobile {
  width: fit-content;
  height: 30px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px 1px 8px;
  white-space: nowrap;
  font-size: 1.5rem;
  color: $white;
  background-color: $text-primary4;
  border-radius: 4px;
  line-height: 16px;
  &.size-small {
    height: 24px;
    padding: 0 12px 1px 12px;
  }
  &.color-gray {
    background-color: $text-primary4;
  }
  &.color-red {
    background-color: $system_red;
  }
  &.color-green {
    background-color: $system_green;
  }
  &.color-brown {
    background-color: $key1;
  }
  &.color-blue {
    background-color: $system-linked;
  }
}

.only-text-btn {
  background-color: transparent;
  border: none;
  font-size: 1.4rem;
  color: $text-primary;
  white-space: nowrap;
  &.color-green {
    color: $system_green;
  }
}

.img-remove-btn {
  background-color: transparent;
  border: none;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  right: 8px;
  background-image: url("../../images/ic_close_small_w.svg");
  background-size: 14px;
  padding: 5px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

.top-close-btn {
  background-color: transparent;
  border: none;
  width: 30px;
  height: 30px;
  background-image: url("../../images/ic_close_small_w.svg");
  background-size: 22px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

.plus-btn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: map-get($grays, "900");
  border: none;
  background-image: url("../../images/ic_plus_w.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
  cursor: pointer;
}

.minus-btn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffffff;
  border: solid 1px #191919;
  background-image: url("../../images/ic_minus.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
  cursor: pointer;
}

.circle-plus-btn {
  width: 36px;
  height: 36px;
  min-width: 36px;
  background-color: $white;
  border: 1px solid $line_light;
  border-radius: 50px;
  position: relative;
  background-image: url("../../images/ic_plus.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
  &:disabled {
    &::after {
      content: "";
      position: absolute;
      left: 2px;
      top: 2px;
      width: 30px;
      height: 30px;
      border-radius: 50px;
      background-color: $white;
      opacity: 0.7;
    }
  }
}

.circle-minus-btn {
  width: 36px;
  height: 36px;
  min-width: 36px;
  background-color: $white;
  border: 1px solid $line_light;
  border-radius: 50px;
  background-image: url("../../images/ic_minus.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  &:disabled {
    &::after {
      content: "";
      position: absolute;
      left: 2px;
      top: 2px;
      width: 30px;
      height: 30px;
      border-radius: 50px;
      background-color: $white;
      opacity: 0.7;
    }
  }
}

.search-btn {
  width: 40px;
  height: 40px;
  background: url("../../images/ic_medium_search.svg") no-repeat center;
  border: none;
  background-color: transparent;
}
.clear-btn {
  width: 40px;
  height: 40px;
  background: url("../../images/ic_clear.svg") no-repeat center;
  border: none;
  background-color: transparent;
}

.back-btn {
  width: fit-content;
  padding-left: 30px;
  background-color: transparent;
  color: $white;
  font-size: 1.6rem;
  border: none;
  min-height: 30px;
  background-image: url("../../images/ic_back_w.svg");
  background-position: left;
  background-repeat: no-repeat;
  background-size: 30px;
}

.base-btn {
  width: fit-content;
  white-space: nowrap;
  font-size: 1.4rem;
  font-weight: 500;
  background-color: $text-primary;
  border: 1px solid $text-primary;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 18px;
  color: $white;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: $text-primary3;
    border: 1px solid $text-primary3;
  }
  &:disabled {
    background-color: $text-primary5;
    border-color: $text-primary5;
    &:hover {
      background-color: $text-primary5;
      cursor: default;
    }
  }
  &.size-small {
    padding: 0 10px;
    font-size: 1.4rem;
    height: 34px;
    line-height: 20px;
  }
  &.size-medium {
    padding: 0 20px;
    font-size: 1.4rem;
    height: 40px;
  }
  &.size-fixed {
    padding: 0 20px;
    min-width: 100px;
  }
  &.size-large {
    min-width: 100px;
    padding: 0 20px;
    font-size: 1.5rem;
    height: 54px;
    line-height: 22px;
  }
  &.color-white {
    background-color: $white;
    border: 1px solid $line_light;
    color: $text-primary;
    &:hover {
      border: 1px solid $text-primary4;
    }
    &.no-outline {
      border: 0;
    }
    &:disabled {
      color: $text-primary4;
      background-color: $bg_light;
      &:hover {
        border-color: $line_light;
      }
    }
  }
  &.color-blue {
    background-color: $system-linked;
    border: 1px solid $line_light;
    color: $white;
    &:hover {
      border: 1px solid $system-linked;
    }
    &.no-outline {
      border: 0;
    }
    &:disabled {
      color: $text-primary4;
      background-color: $bg_light;
      &:hover {
        border-color: $line_light;
      }
    }
  }

  &.icon-excel {
    padding-left: 40px;
    &::before {
      content: "";
      width: 22.62px;
      height: 20.7px;
      position: absolute;
      left: 8px;
      background: url("../../images/ic_excel.svg") no-repeat;
      background-size: contain;
    }
  }

  &.icon-refresh {
    padding-left: 40px;
    &::before {
      content: "";
      width: 22.62px;
      height: 20.7px;
      position: absolute;
      left: 13px;
      background: url("../../images/ic_refresh.svg") no-repeat;
      background-size: contain;
    }
  }

  &.icon-trash {
    padding-left: 40px;
    &::before {
      content: "";
      width: 22.62px;
      height: 20.7px;
      position: absolute;
      left: 13px;
      background: url("../../images/ic_trash_g.svg") no-repeat;
      background-size: contain;
    }
  }

  &.icon-invite {
    padding-right: 40px;
    &::after {
      content: "";
      width: 15px;
      height: 15px;
      position: absolute;
      left: 85px;
      top: 13px;
      background: url("../../images/ic_invite.svg") no-repeat;
      background-size: contain;
    }
  }
}

.base-search-btn {
  font-size: 1.4rem;
  font-weight: 500;
  border: 1px solid map-get($grays, "200");
  border-radius: 4px;
  display: flex;
  align-items: center;
  min-width: 40px;
  width: 40px;
  height: 40px;
  padding: 0 16px;
  cursor: pointer;
  background: url("../../images/ic_search.svg") no-repeat $bg_dark;
  background-position: center;
  &:hover {
    background: url("../../images/ic_search.svg") no-repeat $bg_dark;
    background-position: center;
  }
}
.base-clear-btn {
  // 인풋에 들어간 x 버튼
  border: none;
  width: 14px;
  height: 14px;
  background: url("../../images/ic_input_clear_b.svg") no-repeat;
  background-position: center;
  cursor: pointer;
}
.base-add-btn {
  // 원형에 플러스
  width: 20px;
  min-width: 20px;
  height: 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
  background-image: url("../../images/ic_plus_circle_b.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.base-erase-btn {
  //원형에 마이너스
  width: 20px;
  min-width: 20px;
  height: 20px;
  background-color: $white;
  border: 1px solid map-get($grays, "600");
  border-radius: 50px;
  cursor: pointer;
  position: relative;
  background-image: url("../../images/ic_minus.svg");
  background-position: center;
  background-size: 16px;
  background-repeat: no-repeat;
}

.base-file-btn {
  // 파일업로드 버튼
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-color: $white;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  position: relative;
  background-image: url("../../images/ic_file_upload.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  &:hover {
    background-color: map-get($grays, "200");
  }
}
.base-remove {
  width: 20px;
  min-width: 20px;
  height: 20px;
  background-color: $white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  background-image: url("../../images/ic_close.svg");
  background-position: center;
  background-size: 20px;
  background-repeat: no-repeat;
  &:hover {
    background-color: map-get($grays, "200");
  }
  &.bg-gray {
    background-color: map-get($grays, "200");
  }
}

.base-refresh-btn {
  width: 36px;
  min-width: 36px;
  height: 36px;
  background-color: $white;
  border: 1px solid map-get($grays, "400");
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  background-image: url("../../images/ic_refresh.svg");
  background-position: center;
  background-size: 20px;
  background-repeat: no-repeat;
  &:hover {
    background-color: map-get($grays, "200");
  }
  &.bg-gray {
    background-color: map-get($grays, "200");
  }
}
.base-edit-btn {
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  background-image: url("../../images/ic_edit.svg");
  background-position: center;
  background-size: 16px;
  background-repeat: no-repeat;
  border: none;
  &:disabled {
    opacity: 0.3;
    &:hover {
      background-color: unset;
      cursor: default;
    }
  }
  &:hover {
    background-color: map-get($grays, "300");
  }
}
.base-up-btn {
  width: 20px;
  min-width: 20px;
  height: 24px;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  background-image: url("../../images/ic_up.svg");
  background-position: center;
  background-size: 6px;
  background-repeat: no-repeat;
  border: none;
  &:disabled {
    opacity: 0.3;
    &:hover {
      background-color: unset;
      cursor: default;
    }
  }
  &:hover {
    background-color: map-get($grays, "300");
  }
}
.base-down-btn {
  width: 20px;
  min-width: 20px;
  height: 24px;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  background-image: url("../../images/ic_down.svg");
  background-position: center;
  background-size: 6px;
  background-repeat: no-repeat;
  border: none;
  &:disabled {
    opacity: 0.3;
    &:hover {
      background-color: unset;
      cursor: default;
    }
  }
  &:hover {
    background-color: map-get($grays, "300");
  }
}

.base-setting-btn {
  width: 20px;
  min-width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  background-image: url("../../images/ic_setting_thin.svg");
  background-position: center;
  background-size: 20px;
  background-repeat: no-repeat;
  border: none;
  &:disabled {
    opacity: 0.3;
    &:hover {
      background-color: unset;
      cursor: default;
    }
  }
  &:hover {
    background-color: map-get($grays, "300");
  }
}

.base-trash-btn {
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  background-image: url("../../images/ic_trash_r.svg");
  background-position: center;
  background-size: 16px;
  background-repeat: no-repeat;
  border: none;
  &:disabled {
    opacity: 0.3;
    &:hover {
      background-color: unset;
      cursor: default;
    }
  }
  &:hover {
    background-color: map-get($grays, "300");
  }
  &.color-bg-gray {
    background-image: url("../../images/ic_trash_g.svg");
  }
  &.color-gray {
    width: 20px;
    min-width: 20px;
    height: 20px;
    background-image: url("../../images/ic_trash_g.svg");
    &:hover {
      background-color: unset;
    }
    &.size-20 {
      background-size: 20px;
    }
  }
}
.base-menu-btn {
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  background-image: url("../../images/ic_menu_g.svg");
  background-position: center;
  background-size: 16px;
  background-repeat: no-repeat;
  border: none;
  &:disabled {
    opacity: 0.3;
    &:hover {
      background-color: unset;
      cursor: default;
    }
  }
  &:hover {
    background-color: map-get($grays, "300");
  }
}

.login-btn {
  width: 240px;
  height: 40px;
  white-space: nowrap;
  font-size: 1.6rem;
  font-weight: 700;
  background-color: map-get($grays, "900");
  border: 1px solid map-get($grays, "900");
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  line-height: 16px;
  cursor: pointer;
}

// 네모 회색 오른쪽 위
.absolute-remove-btn {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 16px;
  right: 16px;
  background-color: map-get($grays, "400");
  background-image: url("../../images/ic_close_w.svg");
  background-position: center;
  background-size: 20px;
  background-repeat: no-repeat;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

// 선택시 검정 칩 따로 이동
.base-chip {
  height: 30px;
  width: fit-content;
  font-size: 1.2rem;
  white-space: nowrap;
  background-color: $white;
  border: 1px solid $line-light;
  border-radius: 50px;
  color: $text-primary;
  padding: 2px 10px;
  &.--active {
    background-color: $text-primary;
    border-color: $text-primary;
    color: $white;
  }
  &:hover {
    border-color: $text-primary;
  }
}

.type {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 51px;
  max-width: 51px;
  padding: 4px 16px;
  height: 24px;
  min-height: 24px !important;
  color: $white;
  font-size: 1.2rem;
  white-space: nowrap;
  line-height: 1.6rem;
  border-radius: 4px;
  &.time {
    background-color: $key3;
  }
  &.open {
    background-color: $key2;
  }
  &.full {
    background-color: $key1;
  }
  &.non-resident {
    background-color: $bg_light;
    border: 1px solid $bg_dark;
    color: $text_primary3;
  }
  &.service {
    background-color: $key4;
  }
}

//temp
.color-chip {
  padding: 2px 10px;
  font-size: 1.1rem;
  border-radius: 50px;
  width: fit-content;
  font-size: 1.2rem;
  position: relative;
  span {
    font-size: 1.2rem !important;
  }
  &.visible {
    background-color: map-get($greens, "100");
    color: map-get($greens, "600");
    padding-bottom: 3px;
  }

  &.green {
    background-color: map-get($greens, "100");
    color: map-get($greens, "600");
    padding-bottom: 3px;
    &.dot {
      padding-left: 18px;
      &::before {
        content: "";
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        background-color: map-get($greens, "600");
      }
    }
  }
  &.invisible {
    background-color: map-get($reds, "100");
    color: map-get($reds, "900");
    padding-bottom: 3px;
  }
  &.red {
    background-color: map-get($reds, "100");
    color: map-get($reds, "900");
    padding-bottom: 3px;
  }
  &.draft {
    background-color: map-get($grays, "100");
    color: map-get($grays, "500");
    padding-bottom: 3px;
  }
  &.gray {
    background-color: map-get($grays, "100");
    color: map-get($grays, "500");
    padding-bottom: 3px;
  }
  &.sell {
    background-color: map-get($blues, "100");
    color: map-get($blues, "900");
    padding-left: 20px;
    padding-bottom: 3px;

    &::before {
      content: "";
      position: absolute;
      border-radius: 50%;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
      background-color: map-get($blues, "900");
    }
  }
  &.use {
    background-color: map-get($greens, "100");
    color: map-get($greens, "600");
    padding-left: 20px;
    padding-bottom: 3px;

    &::before {
      content: "";
      position: absolute;
      border-radius: 50%;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
      background-color: map-get($greens, "600");
    }
  }
}

.chip {
  //table에서 쓰는 상태 칩
  width: fit-content;
  min-width: 52px;
  height: 24px;
  position: relative;
  display: flex;
  padding: 5px;
  font-size: 1.2rem !important;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: $bg_light;
  border: 1px solid $bg_dark;

  &.visible {
    color: $system_success;
  }
  &.green {
    color: $system_success;
  }
  &.invisible {
    color: $system-red;
  }
  &.red {
    color: $system-red;
  }
  &.draft {
    color: $text_primary3;
  }
  &.gray {
    color: $text_primary3;
  }
  &.blue {
    color: $system_linked;
  }
}

.delete-x-btn {
  min-width: 20px;
  width: 20px;
  height: 20px;
  background-image: url("../../images/ic_close.svg");
  background-size: contain;
  cursor: pointer;
  vertical-align: middle;
}

.status {
  // tag 컬럼 디자인시스템 명칭을 따름
  width: 52px;
  height: 24px;
  padding: 4px 5px;
  font-size: 1.2rem;
  color: $white;
  letter-spacing: -0.48px;
  background-color: $text-primary3;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.A {
    background-color: $system_linked;
  }
  &.B {
    background-color: $system_success;
  }
  &.C {
    background-color: $system_red;
  }
  &.D {
    background-color: $text_primary3;
  }
  &.E {
    background-color: $system_blue_07;
    color: $system_linked;
    font-weight: 600;
  }
  &.F {
    background-color: $system_green_07;
    color: $system_green;
    font-weight: 600;
  }
  &.G {
    background-color: $system_red_07;
    color: $system_red;
    font-weight: 600;
  }
  &.H {
    background-color: $system-gray_07;
    color: $text-primary3;
    font-weight: 600;
  }
  &.I {
    background-color: $system_purple_07;
    color: $system_purple;
    font-weight: 600;
  }
}
