.react-select-container {
  width: 100%;
  .react-select__control {
    border-color: $line-light;
    min-height: 40px;
    height: 40px;
    transition: unset;
    &:hover {
      border-color: $text-primary;
    }
  }
}

.base-select {
  .react-select {
    &__single-value {
      font-size: 1.4rem;
      color: $text-primary;
    }
    &__indicator-separator {
      display: none;
    }
    &__placeholder {
      font-size: 1.4rem;
      color: $text_primary4;
      white-space: nowrap;
      border-color: $text-primary;
      box-shadow: none;
    }
    &__control--is-focused {
      border-color: $text-primary;
      box-shadow: unset;
      &:hover {
        border-color: $text-primary;
      }
    }
    &__control--is-disabled {
      background-color: $bg_light;
      .react-select__single-value {
        color: $text-primary4;
      }
      .react-select__dropdown-indicator {
        background-image: url("../../images/ic_chevron_down_g.svg");
      }
    }
    &__menu {
      z-index: $select-z-in;
      padding: 8px;
      margin-top: 10px;
      box-shadow: unset;
      filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.1));
      border: none;
      border-radius: 10px;
    }
    &__menu-list {
      @include scroll-hidden;
      .react-select__option {
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        min-height: 36px;
      }
    }
    &__option {
      background-color: $white;
      padding-right: 16px;
    }
    &__option--is-focused {
      // background-color: $bg_light;
      border-radius: 4px;
    }
    &__option--is-selected {
      color: $text_primary;
      font-weight: 600;
      background: url("../../images/ic_check.svg") $white no-repeat center;
      background-position-x: calc(100% - 8px);
      padding-right: 30px;
      &:focus {
        background-color: unset;
      }
      &:hover {
        background-color: $bg_light;
      }
    }

    &__option-has-button {
      background-color: $white;
      padding-right: 10px;
    }

    &__option--is-selected-with-btn {
      color: $text_primary;
      font-weight: 600;
      background: url("../../images/ic_check.svg") $white no-repeat center;
      background-position-x: calc(100% - 34px);
      padding-right: 10px;
      &:focus {
        background-color: unset;
      }
      &:hover {
        background-color: $bg_light;
      }
    }

    &__option:not(.react-select__option--is-selected) {
      &:hover,
      &:active {
        background-color: $bg_light;
      }
    }
    &__indicators {
      margin-right: 10px;
    }
    &__dropdown-indicator {
      width: 16px;
      height: 16px;
      svg {
        display: none;
      }
      background-image: url("../../images/ic_chevron_down_b.svg");
      background-size: contain;
      background-position: center;
    }
  }

  // 상태값에따른 셀렉트박스 컬러
  &.state-select {
    .react-select {
      &--is-disabled {
        .react-select__control {
          background-color: $text-primary4;
          border-color: $text-primary4;
          &:hover {
            border-color: $text-primary4;
          }
        }
        .react-select__value-container {
          display: flex;
          justify-content: center;
        }
        .react-select__single-value {
          padding-left: 0;
          color: $white;
        }
        .react-select__indicators {
          display: none;
        }
      }
    }
    &.product {
      .react-select__menu-list {
        overflow: visible;
      }
      .react-select__menu-list:first-child {
        margin-top: 36px;
        &::before {
          content: "공간상품의 상태를 선택해 주세요";
          font-size: 1.2rem;
          position: absolute;
          top: -36px;
          left: -8px;
          padding-top: 6px;
          width: 100%;
          border-bottom: 1px solid $bg_dark;
          padding-left: 16px;
          height: 30px;
        }
      }
    }
    &.workorder {
      .react-select__menu-list {
        overflow: visible;
      }
      .react-select__menu-list:first-child {
        margin-top: 36px;
        &::before {
          content: "워크오더의 진행상태를 선택해 주세요";
          font-size: 1.2rem;
          position: absolute;
          top: -36px;
          left: -8px;
          padding-top: 6px;
          width: 100%;
          border-bottom: 1px solid $bg_dark;
          padding-left: 16px;
          height: 30px;
        }
      }
    }
    &.reservation {
      .react-select__menu-list {
        overflow: visible;
      }
      .react-select__menu-list:first-child {
        margin-top: 36px;
        &::before {
          content: "예약 상태를 선택해 주세요";
          font-size: 1.2rem;
          position: absolute;
          top: -36px;
          left: -8px;
          padding-top: 6px;
          width: 100%;
          border-bottom: 1px solid $bg_dark;
          padding-left: 16px;
          height: 30px;
        }
      }
    }
    &.contract {
      .react-select__menu-list {
        overflow: visible;
      }
      .react-select__menu-list:first-child {
        margin-top: 36px;
        &::before {
          content: "계약 상태를 선택해 주세요";
          font-size: 1.2rem;
          position: absolute;
          top: -36px;
          left: -8px;
          padding-top: 6px;
          width: 100%;
          border-bottom: 1px solid $bg_dark;
          padding-left: 16px;
          height: 30px;
        }
      }
    }
    .react-select__menu-list .react-select__option {
      min-height: 46px;
    }

    .react-select {
      &__menu {
        width: 240px;
        right: 0;
      }
      &__option {
        font-size: 1.5rem;
      }
    }
    &.position-left {
      .react-select {
        &__menu {
          left: 0;
          right: unset;
        }
      }
    }
  }
  &.color-gray {
    .react-select {
      &__control {
        background-color: $text-primary4;
        border-color: $text-primary4;
        &:hover {
          border-color: $text-primary4;
        }
      }
      &__single-value {
        padding-left: 2px;
        color: $white;
      }
      &__dropdown-indicator {
        background-image: url("../../images/ic_chevron_down_w.svg");
      }
      &--is-disabled {
        .__control {
          background-color: $text-primary4;
          border-color: $text-primary4;
          &:hover {
            border-color: $text-primary4;
          }
        }
        .react-select__value-container {
          display: flex;
          justify-content: center;
        }
        .react-select__single-value {
          padding-left: 0;
        }
        .react-select__indicators {
          display: none;
        }
      }
    }
  }
  &.color-blue {
    .react-select {
      &__control {
        background-color: $system_linked;
        border-color: $system_linked;
        &:hover {
          border-color: $system_linked;
        }
      }
      &__single-value {
        padding-left: 2px;
        color: $white;
      }
      &__dropdown-indicator {
        background-image: url("../../images/ic_chevron_down_w.svg");
      }
    }
  }
  &.color-green {
    .react-select {
      &__control {
        background-color: $system_success;
        border-color: $system_success;
        &:hover {
          border-color: $system_success;
        }
      }
      &__single-value {
        padding-left: 2px;
        color: $white;
      }
      &__dropdown-indicator {
        background-image: url("../../images/ic_chevron_down_w.svg");
      }
    }
  }
  &.color-red {
    .react-select {
      &__control {
        background-color: $system_red;
        border-color: $system_red;
        &:hover {
          border-color: $system_red;
        }
      }
      &__single-value {
        padding-left: 2px;
        color: $white;
      }
      &__dropdown-indicator {
        background-image: url("../../images/ic_chevron_down_w.svg");
      }
    }
  }
}

.base-multi-select-wrap {
  position: relative;
  .base-multi-select {
    width: 100%;
    height: 40px;

    border: 1px solid $line-light;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 30px;

    &:hover {
      border: 1px solid $text-primary;
    }
    &:focus {
      border-color: $text-primary;
    }

    &__disabled {
      background-color: $bg_light;
      .base-multi-select__chevron {
        display: none;
      }
    }
    &__value {
      font-size: 1.4rem;
      color: $text-primary;
      cursor: default;
      @include ellipsis;
    }
    &__placeholder {
      font-size: 1.4rem;
      color: $text-primary4;
    }
    &__chevron {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 11px;
      right: 10px;
      background-image: url("../../images/ic_chevron_down_b.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    &__menu {
      width: 100%;
      min-height: 100px;
      position: absolute;
      top: 40px;
      z-index: $select-z-in;
      padding: 8px;
      margin-top: 10px;
      box-shadow: unset;
      background-color: $white;
      filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.1));
      border: none;
      border-radius: 10px;
      &__menu-list {
        list-style: none;
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        min-height: 36px;
        border-radius: 4px;
        padding: 7.5px 8px;
        cursor: pointer;
        &:hover {
          background-color: $bg_light;
        }
        &.list-all {
          text-decoration: underline;
          &:hover {
            background-color: $white;
            font-weight: 600;
          }
        }
        label {
          cursor: pointer;
        }
      }
    }
  }
}

// 포탈용
.react-select__menu-portal {
  z-index: $select-z-in !important;
  .react-select__menu {
    padding: 8px;
    margin-top: 10px;
    box-shadow: unset;
    filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.1));
    border: none;
    border-radius: 10px;
  }
  .react-select__menu-list {
    @include scroll-hidden;
    .react-select__option {
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      min-height: 36px;
    }
  }
  .react-select__option {
    background-color: $white;
    padding-right: 16px;
  }
  .react-select__option--is-focused {
    background-color: $bg_light;
    border-radius: 4px;
  }
  .react-select__option--is-selected {
    color: $text_primary;
    font-weight: 600;
    background: url("../../images/ic_check.svg") $white no-repeat center;
    background-position-x: calc(100% - 8px);
    padding-right: 30px;
    &:focus {
      background-color: unset;
    }
    &:hover {
      background-color: $bg_light;
    }
  }
  .react-select__option:not(.react-select__option--is-selected) {
    &:hover,
    &:active {
      background-color: $bg_light;
    }
  }

  .react-select__option-has-button {
    background-color: $white;
    padding-right: 10px;
  }

  // 톱니바퀴 버튼이 있는 경우의 스타일 추가
  .react-select__option--is-selected-with-btn {
    color: $text_primary;
    font-weight: 600;
    background: url("../../images/ic_check.svg") $white no-repeat center;
    background-position-x: calc(100% - 34px);
    padding-right: 10px;
    &:focus {
      background-color: unset;
    }
    &:hover {
      background-color: $bg_light;
    }
  }
}
