.spinner {
  width: 100px;
  height: 100px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid black;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &.small {
    width: 19px;
    height: 19px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid black;
  }
}
