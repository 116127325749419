.global-nav {
  height: $gnb-height;
  width: calc(100% - #{$sidebar-width});
  background-color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: $contents-padding-left;
  padding-right: $contents-padding-right;
  z-index: $gnb-z-in;
  border-bottom: 1px solid $border-color;
  margin-left: $sidebar-width;
  position: relative;
  &.no-sidebar-page {
    width: 100%;
    margin-left: unset;
  }
  &__logo {
    width: 75px;
    height: 30px;
    background-image: url("../../images/logo_ctrlroom_b.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  &__title {
    font-size: 2.2rem;
    font-weight: bold;
  }
  span {
    font-size: 13px;
    font-weight: 400;
  }
  &__user-wrap {
    display: flex;
    align-items: center;
    span {
      font-size: 1.4rem;
    }
  }

  &__user {
    position: relative;
    padding-right: 20px;
    cursor: pointer;
    &::after {
      content: "";
      width: 12px;
      height: 12px;
      background-image: url("../../images/ic_chevron_down_b.svg");
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-45%);
    }
  }
  &__avatar {
    width: 30px;
    height: 30px;
    background-color: $system_linked;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 700;
    color: $white;
    margin-left: 10px;
  }

  &__dropdown {
    min-width: 280px;
    height: auto;
    position: absolute;
    background-color: $white;
    box-shadow: 1px 2px 10px rgba(36, 36, 36, 0.12);
    border-radius: 8px;
    top: 38px;
    right: 0;
    padding: 0px;
    z-index: $gnb-z-in;

    .global-nav__avatar {
      width: 48px;
      height: 48px;
      font-size: 1.4rem;
      margin-left: unset;
    }
    &-user {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 20px;
      .user-info {
        margin-left: 10px;
        p {
          font-size: 1.4rem;
          font-weight: 700;
          margin-bottom: 5px;
        }
        span {
          font-size: 1.4rem;
          color: $text_primary3;
        }
      }
    }

    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        font-size: 14px;
        color: map-get($grays, "600");
        cursor: pointer;
        &:hover {
          color: map-get($grays, "900");
        }
      }
    }
  }
  &__partner-list-wrap {
    padding: 16px 10px 8px 10px;
    border-bottom: 1px solid $bg_dark;
  }
  &__partner-list {
    max-height: 548px;
    overflow-y: auto;
    @include scroll-hidden;
    position: relative;
    
    h5 {
      font-size: 1.2rem;
      color: $text_primary3;
      font-weight: 400;
      padding: 0 10px;
    }
    ul {
      li {
        margin-top: 4px;
        padding: 8px 10px;
        position: relative;
        cursor: pointer;
        &:hover {
          color: unset;
          background-color: $bg-light;
          border-radius: 4px;
        }
        p {
          font-size: 1.4rem;
          color: $text_primary;
        }
        span {
          font-size: 1.1rem;
          color: $text_primary3;
        }
        &.--active {
          p {
            font-size: 1.5rem;
            font-weight: 600;
          }
          &::after {
            content: "";
            position: absolute;
            width: 22px;
            height: 22px;
            right: 8px;
            top: 6px;
            background-image: url("../../images/ic_check.svg");
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
    }
  }
}
