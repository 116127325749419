@font-face {
  font-family: "Apple SD Gothic Neo";
  src: url("../../fonts/AppleSDGothicNeoR.woff2") format("woff2"), url("../../fonts/AppleSDGothicNeoR.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  unicode-range: U+AC00-D7A3, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E;
}
@font-face {
  font-family: "Apple SD Gothic Neo";
  src: url("../../fonts/AppleSDGothicNeoM.woff2") format("woff2"), url("../../fonts/AppleSDGothicNeoM.woff") format("woff");
  font-weight: 500;
  font-style: medium;
  unicode-range: U+AC00-D7A3, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E;
}
@font-face {
  font-family: "Apple SD Gothic Neo";
  src: url("../../fonts/AppleSDGothicNeoSB.woff2") format("woff2"), url("../../fonts/AppleSDGothicNeoSB.woff") format("woff");
  font-weight: 600;
  unicode-range: U+AC00-D7A3, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E;
}

@font-face {
  font-family: "Apple SD Gothic Neo";
  src: url("../../fonts/AppleSDGothicNeoB.woff2") format("woff2"), url("../../fonts/AppleSDGothicNeoB.woff") format("woff");
  font-weight: 700;
  font-style: bold;
  unicode-range: U+AC00-D7A3, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/RobotoRegular.woff2") format("woff2"), url("../../fonts/RobotoRegular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0030-0039, U+0041-005A, U+0061-007A;
}
@font-face {
  font-family: "Roboto";
  src: url("../../fonts/RobotoMedium.woff2") format("woff2"), url("../../fonts/RobotoMedium.woff") format("woff");
  font-weight: 500;
  font-style: bold;
  unicode-range: U+0030-0039, U+0041-005A, U+0061-007A;
}
@font-face {
  font-family: "Roboto";
  src: url("../../fonts/RobotoMedium.woff2") format("woff2"), url("../../fonts/RobotoMedium.woff") format("woff");
  font-weight: 600;
  font-style: bold;
  unicode-range: U+0030-0039, U+0041-005A, U+0061-007A;
}
@font-face {
  font-family: "Roboto";
  src: url("../../fonts/RobotoBold.woff2") format("woff2"), url("../../fonts/RobotoBold.woff") format("woff");
  font-weight: 700;
  font-style: bold;
  unicode-range: U+0030-0039, U+0041-005A, U+0061-007A;
}

.hd9 {
  color: #000;
  /* KOR head/HD9 18sb */
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.72px;
}
.BD5 {
  //styleName: Kor/Body/BD5 14sb;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.04em;
  text-align: left;
}
.BD6 {
  //styleName: Kor/Body/BD6 14rg;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.04em;
  text-align: left;
}
