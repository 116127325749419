.accordion {
  margin-bottom: 20px;
}

.accordion-header {
  cursor: pointer;
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.accordion-content {
  display: none;
  padding: 10px;
  border-top: 1px solid #ddd;
}
