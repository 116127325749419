.file-viewer-pdf {
  max-width: calc(100vw - 60px);
  max-height: calc(100vh - 100px);
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  // border: 1px solid black;
  position: relative;
  min-width:614px;
  &__document {
    max-width: calc(100vw - 60px);
    max-height: calc(100vh - 100px);
    overflow: scroll;
    @include scroll-hidden;
  }

  .react-pdf__Page__annotations.annotationLayer {
    display: none;
  }
  .react-pdf__message.react-pdf__message--loading {
    display: none;
  }
  .button-wrap {
    button {
      position: absolute;
      width: 30px;
      height: 30px;
      &:disabled {
        opacity:0.3;
    }
      &.left {
        left: -50px;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
        background: url("../../images/ic_chevron_down_w.svg");
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
      }
      &.right {
        right: -50px;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        background: url("../../images/ic_chevron_down_w.svg");
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
      }
    }
  }
}
