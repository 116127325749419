.contents-question {
  width: 100%;
  // height: 360px;
  background-color: #f8f8fa;
  border-radius: 10px;

  .base-textarea {
    width: 775px;
  }

  &__answer {
    background-color: #ffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
  }
}

.questions-more {
  width: 100% !important;
}

.auto-height {
  td {
    height: auto;
    .h-40 {
      height: 40px;
      border-bottom: 1px solid map-get($grays, "200");
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.list-comma {
  ::after {
    content: ",";
    // margin-left: 5px;
  }
}

.chip-cs {
  //table에서 쓰는 상태 칩
  width: fit-content;
  min-width: 51px;
  height: 24px;
  position: relative;
  display: flex;
  padding: 5px;
  font-size: 1.2rem !important;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: $bg_light;
  border: 1px solid $bg_dark;

  &.visible {
    color: $system_success;
  }
  &.green {
    color: $system_success;
  }
  &.invisible {
    color: $system-red;
  }
  &.red {
    color: white;
    background-color: $system-red;
  }
  &.draft {
    color: $text_primary3;
  }
  &.gray {
    color: white;
    background-color: $text_primary3;
  }
  &.blue {
    color: white;
    background-color: $system_linked;
  }
}
.input-white {
  :read-only {
    background-color: white !important;
  }

  :disabled {
    background-color: $bg_light !important;
  }
}

.input-border-1 {
  input {
    border-left: #ffff;
  }
}
