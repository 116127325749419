.page-user-list {
    .table-child {
        position: absolute;

        height: 100%;

        display: flex;
        align-items: center;

        top: 0;
        left: 30px;

        font-size: 1.4rem;
    }
}

.user-base-modal-title {
    font-weight: 700;
    font-size: 2.4rem;

    color: #000000;

    margin-bottom: 30px;
}

.user-base-modal-body {
    width: 480px;
    max-height: 550px;
    overflow-y: auto;

    .user-base-modal-body-subtitle {
        padding: 27px 0;
        display: flex;
        align-items: center;


        .user-base-modal-body-subtitle-fail {
            font-weight: 600;
            font-size: 18px;

            margin-right: 10px;
        }

        .user-base-modal-body-subtitle-count {
            font-size: 14px;
            color: #767676;
        }
    }

    .user-base-modal-body-list {
        list-style: none;

        &>li {
            display: flex;
            align-items: center;
            justify-content: space-between;

            font-weight: 400;
            font-size: 14px;

            height: 50px;

            border-bottom: 1px solid #E0E0E0;
        }
    }
}

.user-base-modal-footer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
}
