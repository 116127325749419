.custom-picker-wrap {
  position: absolute;
  top: 50px;
  z-index: 20;
  width: 120px;
  background-color: white;
  box-shadow: 0px 2px 6px rgba(36, 36, 36, 0.12);
  border-bottom-left-radius: 10px;
}

.custom-timepicker-header {
  color: white;
  background-color: #242424;
  width: 100%;
  height: 36px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  // font-weight: bold;
}

.custom-datepicker {
  list-style: none;
  height: 200px;
  width: 120px;
  // border: 1px solid;
  overflow-y: scroll;
  width: 100%;

  .time-option {
    padding: 5px 10px;
    font-size: 14px;
    text-align: center;
    height: 30px;
  }

  .time-option:hover {
    cursor: pointer;
    background-color: map-get($grays, "200");
  }

  .selected-time {
    background-color: #474747;
    color: white;
    font-weight: bold;
    padding: 5px 10px;
    font-size: 14px;
    text-align: center;
    height: 30px;
    cursor: pointer;
  }
}
.time-picker-disabled {
  color: map-get($grays, "400");
  cursor: default !important;
}
.time-picker-disabled:hover {
  background-color: white !important;
}
