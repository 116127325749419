.page-init {
  width: 100%;
  min-height: $contents;
  max-height: $contents;
  background-color: $bg_light;
  overflow: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  h1 {
    font-size: 2.4rem;
    margin-bottom: 40px;
    text-align: center;
  }
  &__bin {
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 30px;
  }
  &__contents {
    flex-grow: 1;
    flex-shrink: 1;
    $boxWidth:240px;
    .partner-box-wrap {
      display: flex;
      flex-wrap: wrap;
      max-width:$boxWidth * 4 + 30;
      column-gap: 10px;
      justify-content: center;
      row-gap: 22px;
      &.justify-content-start{
        justify-content: start;
      }
    }
    .partner-box {
      width: $boxWidth;
      height: 110px;
      background-color: $white;
      border-radius: 8px;
      padding: 20px;
      cursor: pointer;
      
      &__title {
        font-size: 1.6rem;
        font-weight: 600;
      }
      &__sub-title {
        font-size: 1.2rem;
        font-weight: 400;
        color: $text_primary3;
      }
      &__last-login {
        p {
          text-align: right;
          padding-top: 16px;
          font-size:1.2rem;
          color:$text_primary3;
        }
      }
      &.empty{
       display:flex;
       align-items: center; 
      }
    }
  }
  &__footer {
    padding: 40px;
    margin-top: 35px;
    p {
      font-size: 1.4rem;
      font-weight: 600;
      text-align: center;
      span{
        color:$text-primary3;
        font-weight:400;
        margin-left:4px;
      }
    }
  }
}
