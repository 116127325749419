.sidebar {
  min-width: 200px;
  width: $sidebar-width;
  height: 100%;
  overflow: auto;
  @include scroll-hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  background-color: map-get($grays, "900");

  &__logo {
    width: 75px;
    height: 30px;
    background: url("../../images/logo_ctrlroom_w.svg") no-repeat;
    background-position: center;
    background-size: contain;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 30px;
    cursor: pointer;
  }

  &__divider {
    width: calc(100% - 40px);
    height: 1px;
    background-color: $text-primary2;
    margin: 10px 20px;
    opacity: 0.5;
  }

  ul {
    padding: 0;
    li {
      list-style: none;
      height: auto;
      margin-bottom: 5px;
      // display: flex;
      // align-items: center;
      cursor: pointer;

      &.preparing {
        opacity: 0.3;
      }

      &:hover {
        // background-color: map-get($grays, "800");
        > a {
          color: $white;
        }
      }

      a,
      p {
        font-size: 1.5rem;
        color: $text-primary4;
        font-weight: 400;
        width: 100%;
        height: 100%;
        min-height: 40px;
        padding-left: 20px;
        display: flex;
        align-items: center;

        &.active {
          // background-color: map-get($grays, "800");
          color: $white;
        }
      }

      .icon {
        background-image: url("../../images/ic_help.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 14px;
        height: 14px;
        min-width: 14px;
        min-height: 14px;
        margin-right: 10px;
      }

      &.main {
        .icon {
          background-image: url("../../images/ic_dashboard.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_dashboard_w.svg");
        }
      }

      &.building {
        .icon {
          background-image: url("../../images/ic_building.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_building_w.svg");
        }
      }

      &.monitoring {
        .icon {
          background-image: url("../../images/ic_graph.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_graph_w.svg");
        }
      }

      &.product {
        .icon {
          background-image: url("../../images/ic_product.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_product_w.svg");
        }
      }

      &.contract {
        .icon {
          background-image: url("../../images/ic_contract.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_contract_w.svg");
        }
      }

      &.billing {
        .icon {
          background-image: url("../../images/ic_calendar.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_calendar_w.svg");
        }
      }

      &.price {
        .icon {
          background-image: url("../../images/ic_price_g.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_price.svg");
        }
      }

      &.provider {
        .icon {
          background-image: url("../../images/ic_provider.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_provider_w.svg");
        }
      }

      &.reservation {
        .icon {
          background-image: url("../../images/ic_book.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_book_w.svg");
        }
      }

      &.member {
        .icon {
          background-image: url("../../images/ic_star.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_star_w.svg");
        }
      }

      &.org {
        .icon {
          background-image: url("../../images/ic_organization.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_organization_w.svg");
        }
      }

      &.user {
        .icon {
          background-image: url("../../images/ic_user.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_user_w.svg");
        }
      }

      &.visitor {
        .icon {
          background-image: url("../../images/ic_visitor.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_visitor_w.svg");
        }
      }

      &.notice {
        .icon {
          background-image: url("../../images/ic_speaker.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_speaker_w.svg");
        }
      }

      &.dollar {
        .icon {
          background-image: url("../../images/ic_dollar.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_dollar_w.svg");
        }
      }

      &.workorder {
        .icon {
          background-image: url("../../images/ic_workorder.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_workorder_w.svg");
        }
      }

      &.cost {
        .icon {
          background-image: url("../../images/ic_creditcard.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_creditcard_w.svg");
        }
      }

      &.monitor {
        .icon {
          background-image: url("../../images/ic_monitor.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_monitor_w.svg");
        }
      }

      &.question {
        .icon {
          background-image: url("../../images/ic_question.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_question_w.svg");
        }
      }

      &.voc {
        .icon {
          background-image: url("../../images/ic_voc.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_voc_w.svg");
        }
      }
      &.setting {
        .icon {
          background-image: url("../../images/ic_setting.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_setting.svg");
        }
      }
    }

    .two-depth-menu {
      a {
        padding-left: 40px;
      }
    }
  }

  &__manual {
    padding: 15px 20px;

    button {
      background-color: transparent;
      width: 100%;
      border: 1px solid $text-primary2;
      border-radius: 4px;
      height: 44px;
      text-align: left;
      padding: 0 20px;
      color: $white;
      font-size: 1.4rem;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 14px;
        height: 14px;
        background-image: url("../../images/ic_sidebar_arrow_w.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  &__footer {
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;

    p {
      font-weight: 600;
      font-size: 1.5rem;
      color: $text-primary4;
    }

    span {
      font-size: 1.2rem;
      margin-top: 4px;
      color: $text-primary4;
    }
  }
}
