.base-segment {
    background-color: map-get($grays, "100");
    width: fit-content;
    min-height: 36px;
    display: flex;
    align-items: center;
    border-radius: 100px;
    padding: 0 4px;
    label {
        height: 30px;
        border: none;
        display: flex;
        align-items: center;
        border-radius: 100px;
        margin-right: 10px;
        font-size: 1.3rem;
        cursor: pointer;
        color: map-get($grays, "500");
        background-color: transparent;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 1px;

        &:last-child {
            margin-right: unset;
        }
        &:hover {
            color: map-get($grays, "800");
        }
    }
    input {
        &:checked + label {
            background-color: map-get($greens, "900");
            color: white;
        }
    }
}
