// central 에서 사용하는 컬러 (TODO :: 추후 central 컬러 제거)
$primary: (
  "900": #bd8166,
  "600": #f0a07c,
  "400": #ffc9b0,
  "200": #ffead7,
  "100": #fff7ef,
);

$greens: (
  "900": #497956,
  "600": #68c283,
  "400": #bcecca,
  "200": #def7e7,
  "100": #eafcf1,
);

$reds: (
  "900": #fc6f61,
  "600": #ff6f61,
  "400": #ffc5c0,
  "200": #ffe8e4,
  "100": #fff6f5,
);

$grays: (
  "50": #fafbfc,
  "100": #f8f8f8,
  "200": #f1f1f1,
  "300": #e2e2e2,
  "400": #cacaca,
  "500": #acacac,
  "600": #7c7c7c,
  "700": #474747,
  "800": #2c2c2c,
  "900": #242424,
);

$blues: (
  "100": #eafafc,
  "200": #dff0f7,
  "400": #b6dded,
  "600": #7dc2df,
  "900": #359fcd,
);

$optional: (
  "Track900": #c68b79,
  "Grass900": #6f8776,
  "Wooden900": #b5ad88,
  "Clay900": #9b8885,
  "Ivory900": #f5f3ed,
  "Ivory100": #fbfaf8,
);

// 공통 디자인 시스템 추가
$key1: #c99684;
$key2: #809585;
$key3: #9b8885;
$key4: #bfb790;

$system_red: #f96960;
$system_yellow: #ffd335;
$system_green: #26a687;
$system_success: #26a687;
$system_linked: #007df0;
$system_purple: #5332d0;

$system_red_07: rgba(249, 105, 96, 0.07);
$system_green_07: rgba(38, 166, 135, 0.07);
$system_gray_07: rgba(188, 188, 188, 0.07);
$system_blue_07: rgba(33, 123, 206, 0.07);
$system_purple_07: rgba(83, 50, 208, 0.07);

$text_primary: #191919;
$text_primary2: #525252;
$text_primary3: #767676;
$text_primary4: #999999;
$text_primary5: #c0c0c0;
$line_dark: #dbdbdb;
$line_light: #e0e0e0;

$bg_dark: #f2f4f6;
$bg_light: #f8f8fa;
$white: #ffffff;

//공통 디자인 시스템에 존재하지 않는 변수명
$deep_black: #3a3a3a;
$line_gray: #f2f2f2;
$border-color: #f1f1f1;
$erase-btn: rgba(36, 36, 36, 0.2);
$president: rgba(36, 36, 36, 0.3);

@each $key, $value in $optional {
  .bg-#{$key} {
    background-color: $value !important;
  }
}
@each $key, $value in $primary {
  .text-primary#{$key} {
    color: $value !important;
  }
  .bg-primary#{$key} {
    background-color: $value;
  }
}

@each $key, $value in $greens {
  .text-green#{$key} {
    color: $value !important;
  }
  .bg-green#{$key} {
    background-color: $value !important;
  }
}

@each $key, $value in $reds {
  .text-red#{$key} {
    color: $value !important;
  }
  .bg-red#{$key} {
    background-color: $value !important;
  }
}

@each $key, $value in $grays {
  .text-gray#{$key} {
    color: $value !important;
  }
  .bg-gray#{$key} {
    background-color: $value !important;
  }
}
@each $key, $value in $blues {
  .text-blue#{$key} {
    color: $value !important;
  }
  .bg-blue#{$key} {
    background-color: $value !important;
  }
}

.text-primary {
  color: $text_primary;
}
.text-primary2 {
  color: $text_primary2 !important;
}
.text-primary3 {
  color: $text_primary3 !important;
}
.text-primary4 {
  color: $text_primary4 !important;
}
.text-red {
  color: $system-red !important;
}
.text-green {
  color: $system-green !important;
}
.text-blue {
  color: $system-linked !important;
}
.text-white {
  color: $white !important;
}
