.base-step {
  display: flex;
  align-items: center;
  gap: 30px;
  &__step {
    display: flex;
    align-items: center;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      background-image: url("../../images/ic_step_chevron_right_g.svg");
      background-repeat: no-repeat;
      background-position: center;
      right: -21px;
    }
    &:last-child::after {
      display: none;
    }
    &-circle {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
      font-weight: 400;
      width: 30px;
      height: 30px;
      background-color: $white;
      border: 1px solid $line_light;
      border-radius: 50%;
      color: $text-primary;
    }
    &-name {
      font-size: 1.3rem;
      letter-spacing: -0.52px;
      margin-left: 8px;
      padding-bottom:1px;
      color:$text-primary3;
    }
    &.--active {
      .base-step__step-circle {
        width: 30px;
        height: 30px;
        background-color: $text-primary;
        border: 1px solid $text-primary;
        border-radius: 50%;
        color: $white;
      }
      .base-step__step-name {
        font-size: 1.4rem;
        letter-spacing: -0.52px;
        margin-left: 8px;
        font-weight:600;
        color:$text-primary;
      }
    }
    &.--pass {
      .base-step__step-circle {
        font-weight: 400;
        background-color: $white;
        border: 1px solid $text-primary;
        color: $text-primary;
      }
      .base-step__step-name {
        font-weight:400;
        color:$text-primary3;
      }
    }
  }
}
