$sidebar-width: 220px;
$gnb-height: 65px;
$contents-title-height: 60px;
$contents-padding-left: 30px;
$contents-padding-right: 30px;
$search-wrap-height: 90px;
$pagination-height: 100px;
$btn-wrap: 100px; // 상세/수정의 하단 버튼높이
$inner-tab-table: 50px;

// calculating height
$full-height: calc(var(--vh, 1vh) * 100);

$contents-width: calc(100vw - #{$sidebar-width});

// vh
$full-height: calc(var(--vh, 1vh) * 100);
$contents: calc(#{$full-height} - #{$gnb-height});
$gnb-and-title-and-btnwrap: $gnb-height + $contents-title-height + $btn-wrap;
$table-wrap: calc(100% - (#{$pagination-height} + #{$search-wrap-height}));

// modal
$modal-title-height: 56px;
$modal-contents-height: calc(#{$full-height} - 80px);
$modal-btn-wrap-height: 80px;

// dialog modal
$modal-dialog-btn-wrap-height: 100px;
$modal-dialog-title-height: 90px;

// z-index
// (react-table th = 1)
$header-z-in: 99;
$gnb-z-in: 50;
$select-z-in: 99;
$date-picker-z-in: 99;
$tooltip-z-in: 99;
$toast-z-in: 999;
$dim-z-in: 100;
$btn-wrap-z-in: 98;

// media query
$bp-mobile: 335px;
$bp-tablet: 758px;
$bp-desktop: 1024px;

:export {
  bpTablet: $bp-tablet;
}
