.page-login {
  height:$full-height;
  position: relative;
  background-color: $bg_light;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
  &__content {
    background-color: $white;
    display:flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding:40px 50px;
    border-radius:8px;
    & > div {
      display: flex;
      flex-direction: column;
    }
    
    h1 {
      font-size: 2.4rem;
      margin-bottom: 20px;
    }
    p {
      font-size: 1.4rem;
      font-weight: 500;
    }
    button {
      margin-top: 20px;
      min-width:300px;
      min-height:50px;
      margin-bottom: 10px;
    }
  }
  &__logo {
    width: 90px;
    height: 30px;
    background: url("../../images/logo_ctrlroom_b.svg") no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
    margin-bottom:27px;
  }
  &__sub{
    font-size:1.4rem;
    color:$text-primary3;
    padding-top:20px;
    span{
      font-weight: 600;
    }
  }
}
