.page-visitor {
  .detail {
    a {
      margin-top: 7.5px;
    }
  }

  p {
    white-space: nowrap;
  }
}

.visitor-list-warp {
}

.visitor-table {
  border-collapse: collapse;
  border-spacing: 0;
  th {
    height: 40px;
    border-top: 1px solid map-get($grays, "200");
    border-bottom: 1px solid map-get($grays, "200");

    font-size: 1.4rem;
    color: map-get($grays, "600");
    font-weight: 400;
  }
  td {
    font-size: 1.4rem;
    height: 50px;
    max-height: 50px;
    // overflow:hidden;
    > div {
      display: flex;
      align-items: center;
      min-height: 50px;
      height: auto;
      .base-search-btn {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid map-get($grays, "200");
      &.auto-height {
        td {
          height: auto;
          .h-40 {
            height: 40px;
            border-bottom: 1px solid map-get($grays, "200");
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
    tr.border-top {
      border-top: 1px solid map-get($grays, "200");
    }
  }
  &__img {
    width: 34px;
    height: 34px;
    object-fit: cover;
    background-position: center;
    border-radius: 4px;
  }
}
