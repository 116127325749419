.page-workorder-mobile {
  padding-bottom: 80px;
  max-width: 500px;
  margin: 0 auto;
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      font-size: 1.4rem;
      color: $text_primary5;
    }
    button {
      font-size: 1.4rem;
      color: $line-light;
      background-color: transparent;
      border: none;
      position: relative;
      padding-right: 20px;
      padding-bottom: 2px;
      &::after {
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("../../images/ic_chevron_right_worksheet.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
  &__summary {
    background-color: $text_primary2;
    padding: 24px;
    position: relative;
  }
  &__title-area {
    margin-top: 25px;
    padding-bottom: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .left-area {
      flex: 1;
      padding-right: 20px;
      h1 {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -4%;
        color: $white;
      }
      p {
        font-size: 1.3rem;
        margin-top: 4px;
        color: $line-light;
      }
    }
    .right-area {
      .qr-wrap {
        width: 90px;
        height: 90px;
        border-radius: 8px;
        background-image: url("../../images/workorder_qr_sample.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          color: $white;
          font-size: 1.3rem;
          text-align: center;
        }
        &.--active {
          padding: 8px;
          background-color: $white;
        }
      }
    }
  }
  &__info-area {
    padding-top: 20px;
    border-top: 1px solid rgba(224, 224, 224, 0.2);
    ul {
      list-style: none;
      li {
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        p.label {
          min-width: 98px;
          color: $line-dark;
        }
        p.value {
          color: $white;
          font-size: 1.5rem;
        }
      }
    }
  }
  &__contents {
    padding: 30px 24px;
    &.divider-top-bold {
      border-top: 10px solid $bg_dark;
    }
    h2 {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 22px;
    }
    h3 {
      font-size: 1.7rem;
      font-weight: 600;
      line-height: 2.2rem;
    }
    .contents {
      padding-top: 20px;
      .contents-text {
        font-size: 1.6rem;
      }
      &-two-depth {
        margin-top: 30px;
      }
      &__box {
        border: 1px solid $line_light;
        background-color: $bg_light;
        min-height: 54px;
        border-radius: 4px;
        padding: 16px 10px;
        p {
          font-size: 1.7rem;
          font-weight: 400;
          line-height: 22px;
          &.deleted-officer {
            color: $system_red;
            font-size: 1.5rem;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              width: 24px;
              height: 24px;
              left: -28px;
              background-image: url("../../images/ic_info_r.svg");
            }
          }
        }
      }
      &__img {
        margin-top: 10px;
        display: flex;
        align-items: center;
        $IMG_SIZE: 78px;
        &-wrap {
          position: relative;
          cursor: pointer;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: $IMG_SIZE;
            height: $IMG_SIZE;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
          }
        }
        &-box {
          width: $IMG_SIZE;
          height: $IMG_SIZE;
          border: 1px solid $line_dark;
          border-radius: 4px;
          object-fit: cover;
          object-position: center;
          margin-right: 8px;
          display: block;
          position: relative;
        }
        &-add {
          width: $IMG_SIZE;
          height: $IMG_SIZE;
          position: relative;
          border: 1px dashed $line_dark;
          border-radius: 4px;
          margin-right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            margin-top: 26px;
            font-size: 1.3rem;
            color: $text-primary3;
          }

          cursor: pointer;
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 30px;
            height: 30px;
            transform: translate(-50%, -70%);
            background-image: url("../../images/ic_camera.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 24px;
          }
        }
      }
      &__desc {
        color: $text-primary4;
        padding-top: 10px;
        font-size: 1.7rem;
      }
      .officer-wrap {
        display: flex;
        align-items: center;
        max-width: calc(100% - 118px);
        .officer-part {
          @include ellipsis(1);
          padding-right: 4px;
        }
      }
      .notice-msg {
        color: $system_green;
        font-size: 1.5rem;
      }
    }
  }
  &__contents-list {
    padding: 20px 24px;
    h2 {
      font-size: 2rem;
      font-weight: 600;
      line-height: 3.2rem;
    }
    .contents {
      .list-box {
        width: 100%;
        padding: 20px;
        background-color: $white;
        margin-top: 10px;
        border-radius: 8px;
        border: 1px solid #e0e0e099;
        cursor: pointer;
        &:first-child {
          margin-top: 0;
        }
        &__head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &-left {
            display: flex;
            align-items: center;
          }
          &-right {
            span {
              font-size: 1.4rem;
              color: $text_primary4;
              @include font("roboto");
            }
          }
        }
        &__content {
          font-size: 1.8rem;
          font-weight: 600;
          padding-top: 20px;
        }
        &__info {
          margin-top: 4px;
          span {
            color: $text_primary2;
            font-size: 1.5rem;
            position: relative;
            &:first-child {
              margin-left: 0;
              &::before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .dropdown-wrap {
    padding: 30px 0px;
    border-bottom: 1px solid $line-light;
  }
  .dropdown-button {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 22px;
    background-color: transparent;
    border: none;
    position: relative;
    padding-right: 28px;
    &::after {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      background-image: url("../../images/ic_chevron_down_b.svg");
      background-repeat: no-repeat;
      background-size: contain;
      right: 0;
    }
    &.--active::after {
      transform: rotate(180deg);
    }
  }
  .dropdown-contents {
    display: none;
    &.--active {
      display: block;
    }
    p.extra-info {
      font-size: 1.6rem;
    }
  }
  .detail-empty-case {
    padding-top: 120px;
    h2 {
      text-align: center;
      font-weight: 600;
      font-size: 1.6rem;
      color: $text-primary2;
    }
  }
  // 워크오더 목록
  &.workorder-list {
    min-height: $full_height;
    background-color: $bg_dark;
    padding-bottom: 0;

    .page-workorder-mobile__contents-list {
      padding-bottom: 80px;
      min-height: calc(#{$full_height} - 218px);
      position: relative;
      .list-box {
        &__content {
          p {
            @include ellipsis(1);
          }
        }
      }
      .list-empty-case {
        margin-top: 100px;
        p {
          font-size: 1.7rem;
          font-weight: 600;
          text-align: center;
          color: $text-primary2;
        }
      }
    }

    .page-workorder-mobile__summary {
      padding: 40px 24px 20px 24px;
      &-title-list {
        h1 {
          font-size: 2.4rem;
          color: $white;
        }
        p {
          font-size: 1.5rem;
          color: $white;
          margin-top: 10px;
        }
      }
    }
  }
  .empty-case {
    min-height: calc(#{$full_height} - 140px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 1.8rem;
      font-weight: 600;
    }
    p {
      font-size: 1.7rem;
      color: $text-primary2;
      margin-top: 10px;
    }
  }

  .base-chip-mobile {
    font-size: 1.4rem;
    height: 26px;
    border-radius: 4px;
    margin-left: 8px;
    &:first-child {
      margin-left: 0;
    }
  }
}
.drag-slider {
  width: 100%;

  .swiper-container {
    position: unset;
    .swiper-wrapper {
      align-items: center;
      .swiper-slide {
        height: auto;
        width: 100%;
      }
    }
    .swiper-pagination {
      top: 0;
      left: 0;
      &.swiper-pagination-fraction {
        color: white;
        width: fit-content;
        height: 24px;
        display: flex;
        align-items: center;
        font-size: 1.7rem;
        margin-top: 24px;
        margin-left: 24px;
        box-sizing: border-box;
        @include font("roboto");
      }
    }
  }
}

.bg-white {
  background-color: white !important;
}

.border-y {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.cost-inner-table {
  border-collapse: collapse;
  border-spacing: 0;

  tbody {
    :last-child {
      td {
        div {
          margin-bottom: 0;
        }
      }
    }
  }

  th {
    height: 40px;
    background-color: map-get($grays, "100");
    font-size: 1.4rem;
    color: map-get($grays, "600");
    font-weight: 400;
  }
  td {
    font-size: 1.4rem;
    height: 40px;
    max-height: 40px;
    // overflow:hidden;
    > div {
      display: flex;
      align-items: center;
      min-height: 40px;
      margin-bottom: 10px;
      height: auto;

      .base-search-btn {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  // td {
  //   font-size: 1.4rem;
  //   height: 60px;
  //   max-height: 60px;
  //   // overflow:hidden;
  //   > div {
  //     display: flex;
  //     align-items: center;
  //     min-height: 50px;
  //     height: auto;
  //     .base-search-btn {
  //       top: 50%;
  //       transform: translateY(-50%);
  //     }
  //   }
  // }

  tbody {
    tr {
      border-bottom: 1px solid map-get($grays, "200");
      &.auto-height {
        td {
          height: auto;
          .h-40 {
            height: 40px;
            border-bottom: 1px solid map-get($grays, "200");
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
    tr.border-top {
      border-top: 1px solid map-get($grays, "200");
    }
  }
  &__img {
    width: 34px;
    height: 34px;
    object-fit: cover;
    background-position: center;
    border-radius: 4px;
  }
}

.list-marker {
  list-style: none;

  :first-child ::before {
    content: "";
    width: 3px;
    height: 3px;
    border-radius: 50%;
    margin-right: 8px;
    display: inline-block;
    background-color: $text_primary3;
    vertical-align: middle;
  }
  li {
    color: $text_primary3;
  }
}

.list-marker-red {
  list-style: none;

  :first-child ::before {
    content: "";
    width: 3px;
    height: 3px;
    border-radius: 50%;
    margin-right: 8px;
    display: inline-block;
    background-color: #ff6f61;
    vertical-align: middle;
  }
  li {
    color: $text_primary3;
  }
}

.border-top {
  border-top: 1px solid $bg_light;
}

.border-top-divider {
  border-top: 1px solid #f2f4f6;
  position: relative;
  width: 1000vw;
  left: -30px;
}

.workorder-history {
  ul {
    list-style: none;
    :last-child {
      border: none;
    }
  }

  tr {
    th {
      background-color: #ffffff;
    }
  }
}

.border-y-gray {
  border-bottom: 1px solid map-get($grays, "200");
  border-top: 1px solid map-get($grays, "200");
}

.gray-box {
  background-color: #f8f8fa;
  padding: 25px;
  border-radius: 10px;
}

.bg-yellow {
  background-color: #fffbe3;
}

.workorder-table-height {
  height: auto;
  max-height: 660px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  // table {
  //   border-collapse: separate;
  // }

  // th {
  //   position: sticky;
  //   top: 0;
  //   z-index: 999;
  // }
}
